import { Card, Dialog, DialogActions, TextField, Typography } from '@material-ui/core'
import { Box, Button, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import Service from './Networking/NetworkingUtils';
import { useSnackbar } from "notistack";
import { getCookie } from './Utils/CookieHelper';

const dark = getCookie('theme_mode')


const DirectForgotPassword = () => {

    const [email, setEmail] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [dialog, setDialog] = useState(false)



    const submitEmail = () => {
        if (!email) {
            enqueueSnackbar('Please enter your email', {
                variant: 'warning'
            })
        } else if (!email.includes('@balfourbeatty.com')) {
            enqueueSnackbar('Please enter Balfour Beatty email', {
                variant: 'warning'
            })
        }
        else {
            setSubmitDisabled(true)
            let obj = {
                "email": email
            }
            Service.post('/api/forgot/password/forgot_password/', {
                data: obj
            }).then(res => {
                setDialog(true)
                setEmail(null)
                setSubmitDisabled(false)
            }).catch(error => {
                console.log(error);
                setSubmitDisabled(false)
                if (error.status === 406) {
                    enqueueSnackbar('Email that you are entered does not exist!', {
                        variant: 'warning'
                    })
                } else {
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                }
            });
        }
    }

    const onDialogClose = () =>{
        setDialog(false)
    }

    return (
        <div>
            <Box display='flex' flexDirection="column" justifyContent='center' marginTop='20vh'>
                <Box display="flex" justifyContent="center" >
                    <Card style={{ width: '500px', backgroundColor: dark ? '#272727' : '#eafbf4', }}>
                        <Box sx={{ m: 4 }}>
                            <Typography>Enter your valid email</Typography>
                            <TextField label="Email" fullWidth variant='outlined' id="email"
                                style={{ marginTop: '10px' }}
                                value={email} onChange={e => setEmail(e.target.value)}
                            >
                            </TextField>
                            <Button text="contained"
                                disabled={submitDisabled}
                                color="primary"
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={e => submitEmail(e)}
                            >
                                Submit</Button>
                        </Box>
                    </Card>
                </Box>
            </Box >
            <Dialog fullWidth={true} maxWidth={'xs'} open={dialog} onClose={onDialogClose}>
                <DialogContent>
                    <Box sx={{ fontSize: '16px', fontWeight: 500 }}>
                        Your UserName & Password will be shared to your email shortly!
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={onDialogClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DirectForgotPassword