import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import moment from "moment";

export function screenSizeCheck() {
    if (window.innerWidth <= 1650) {
        return true
    } else {
        return false
    }
}

const kanbanWidth = window.innerWidth * (screenSizeCheck() ? 0.91 : 0.94)
const maxLane = screenSizeCheck() ? 4 : 5
var darkMode = localStorage.getItem("theme_mode")

export function getLaneWidth(len) {
    const widthPx = len <= maxLane ? (kanbanWidth / len) : (kanbanWidth / maxLane)
    return widthPx + "px"
}

export function getCardWidth(len) {
    const widthPx = len <= maxLane ? (kanbanWidth / len) : (kanbanWidth / maxLane)
    return (widthPx * 0.94)
}

export function getPriority() {
    return [
        {
            key: 'urgent',
            name: 'Urgent',
            icon: <NotificationsActiveIcon fontSize='small' />,
            color: '#ff0000'
        },
        {
            key: 'important',
            name: 'Important',
            icon: <PriorityHighIcon fontSize='small' />,
            color: '#ff6666'
        },
        {
            key: 'medium',
            name: 'Medium',
            icon: <FiberManualRecordIcon fontSize='small' />,
            color: '#2eb82e'
        },
        {
            key: 'low',
            name: 'Low',
            icon: <ArrowDownwardIcon fontSize='small' />,
            color: '#00cccc'
        }

    ]
}

export function getTag(tag) {
    let priority = getPriority()
    for (let i = 0; i < priority.length; i++) {
        const element = priority[i];
        if (element.key === tag) {
            return element
        }
    }
    // if (tag === 'urgent') {
    //     return {
    //         key: 'urgent',
    //         name: 'Urgent',
    //         icon: <NotificationsActiveIcon fontSize='small' />,
    //         color: '#ff0000'
    //     }
    // } else if (tag === 'important') {
    //     return {
    //         key: 'important',
    //         name: 'Important',
    //         icon: <PriorityHighIcon fontSize='small' />,
    //         color: '#ff0000'
    //     }
    // } else if (tag === 'medium') {
    //     return {
    //         key: 'medium',
    //         name: 'Medium',
    //         icon: <FiberManualRecordIcon fontSize='small' />,
    //         color: '#2eb82e'
    //     }
    // } else if (tag === 'low') {
    //     return {
    //         key: 'low',
    //         name: 'Low',
    //         icon: <ArrowDownwardIcon fontSize='small' />,
    //         color: '#00cccc'
    //     }
    // }
}

export function dateCheck(start, end, data) {
    let a = moment(end).format('YYYY-MM-DD')
    let b = moment().format('YYYY-MM-DD')
    if ((moment(a).isSame(b)) && (data.laneId !== '4')) {
        if (darkMode === 'dark') {
            return '#e6b800'
        } else {
            return '#ffe066'
        }
    } else if ((moment(a).isBefore(b)) && (data.laneId !== '4')) {
        if (darkMode === 'dark') {
            return '#ff6666'
        } else {
            return '#ff9999'
        }
    }
}

export function getSubTaskStatus() {
    return { 1: "To Do", 2: "InProgress", 3: "Ready For Test", 4: "Closed" }
}
