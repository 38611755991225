import * as React from 'react';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar, withSnackbar } from 'notistack';
import { List, Paper } from '@material-ui/core';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const token = getCookie("bb_metaverse_token");

function ProjectAccessList(props) {

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.left);
  const [right, setRight] = React.useState(props.right);
  const { enqueueSnackbar } = useSnackbar();
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const initialRender = React.useRef(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const postAccess = () => {
    let obj = {}

    for (let index = 0; index < left.length; index++) {
      obj[left[index]] = false
    }
    for (let index = 0; index < right.length; index++) {
      obj[right[index]] = true
    }
    let data = {
      project: props.access.project,
      user: props.access.user,
      access_data: obj,
      id: props.access.id
    }

    Service.post(`/api/projects/level/access/project_level_access_edit/`, {
      headers: {
        Authorization: "Token " + token,
      },
      data: data
    })
      .then(res => {
        props.accessCallback()
      })
      .catch(error => {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
        console.log(error)
      });
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (items) => (
    <Paper style={{ width: 300, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  // sx={{ color: dark ? 'white' : '' }}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={props.lookup[value]} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  React.useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      postAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left, right])

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={() => { handleCheckedRight() }}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={() => { handleCheckedLeft() }}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}

export default withSnackbar(ProjectAccessList);
