import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react'
import Service from '../Networking/NetworkingUtils';
import { useSnackbar, withSnackbar } from 'notistack';
import { getCookie } from '../Utils/CookieHelper';
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ModelLibrary from '../ModelLibrary/ModelLibrary';

var token = getCookie("bb_metaverse_token");
const user = JSON.parse(getCookie("bb_metaverse_user"));

const ModelCategory = () => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const [rowData, setRowData] = useState({})


    const getData = () => {
        setIsLoading(true)
        Service.get('/api/modular/category/', {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    const postData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        Service.post('/api/modular/category/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        }).then(res => {
            getData()
            enqueueSnackbar('Model added Successfully!', {
                variant: 'success'
            })
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    const editData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        Service.put(`/api/modular/category/` + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                enqueueSnackbar('Model edited Successfully', {
                    variant: 'success'
                });
                getData()
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }


    useEffect(() => {
        getData()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div>
            <MaterialTable
                title="Model Category"
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Description', field: 'description' },
                    { title: 'Date Created', field: 'date_created', type: 'date' },
                ]}
                isLoading={isLoading}
                data={data}
                // detailPanel={rowData =>
                //     <Box margin={2}>
                //         <ModelVersion model={rowData} />
                //     </Box>
                // }
                onRowClick={(event, rowData, togglePanel) => {setOpen(true);setRowData(rowData)}}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            postData(newData)
                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            editData(newData)
                            resolve();
                        }),
                    // onRowDelete: oldData =>
                    //   new Promise((resolve, reject) => {
                    //     setTimeout(() => {
                    //       deleteData(oldData)
                    //       const dataDelete = [...data];
                    //       const index = oldData.tableData.id;
                    //       dataDelete.splice(index, 1);
                    //       setData([...dataDelete]);
                    //       resolve()
                    //     }, 1000)
                    //   }),
                }}

                options={{
                    padding: "dense",
                    paging: false,
                    filtering: true,
                    actionsColumnIndex: -1
                }}
            />
            <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                <AppBar position="static">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => setOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {rowData.name}
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                <Box margin={2}>
                    <ModelLibrary rowData={rowData} />
                </Box>
            </Dialog>
        </div>
    )
}

export default withSnackbar(ModelCategory)