// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

var postOpsURL = null
if (window.location.protocol === 'http:') {
  postOpsURL = "http://" + window.location.hostname + ":" + window.location.port
} else {
  postOpsURL = "https://" + window.location.hostname
}

const msTenantId = "a04222fe-0c5c-40bb-8420-97a219ba514e"
export const msClientId = "64073365-5a03-4736-b34e-597376266a0c"

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/' + msTenantId,
    clientId: msClientId,
    redirectUri: postOpsURL,
    postLogoutRedirectUri: postOpsURL,
    validateAuthority: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "openid",
    "email",
    "offline_access",
  ]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  // tokenRefreshUri: window.location.origin + '/auth.html'
}

// clientId: 'f84273ff-b10b-4af3-abf0-4a26dc872e97',
// clientId: 'fb15476e-54f4-46ce-90be-b9557b3b8dd4',

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)