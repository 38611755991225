import { withSnackbar } from 'notistack'
import React from 'react'
import { useSnackbar } from "notistack";
import { getCookie } from '../../Utils/CookieHelper';
import { useEffect } from 'react';
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import Service from '../../Networking/NetworkingUtils';
import { Button, DialogTitle, TextField, DialogContent, Dialog, Box, DialogActions, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import VersionTable from './VersionTable';

var token = getCookie("bb_metaverse_token");
// var access = JSON.parse(getCookie("bb_metaverse_access"));
var user = JSON.parse(getCookie("bb_metaverse_user"))


const AssetTable = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(true)
    const [assetDialog, setAssetDialog] = React.useState(false)
    const [versionDialog, setversionDialog] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [assets, setAssets] = React.useState([])
    const [name, setName] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [assetType, setAssetType] = React.useState(null)
    const [fileName, setFileName] = React.useState('')
    const [packageName, setPackageName] = React.useState('')
    const [methodType, setMethodType] = React.useState('Add')
    const [buttonDisabled, setButtonDisabled] = React.useState(false)
    const [selectedRow, setSelectedRow] = React.useState(null)
    const [active, setActive] = React.useState(null)

    useEffect(() => {
        getData()
        getAssets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getData() {
        Service.get(`/api/projects/asset/?project=${props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                setTableData(res.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function getAssets() {
        Service.get(`/api/asset/types/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                setAssets(res.data)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function handleClose() {
        setButtonDisabled(false)
        setAssetDialog(false)
        setName('')
        setDescription("")
        setAssetType(null)
        setFileName('')
        setPackageName('')
        setActive(null)
        setSelectedRow(null)
    }

    function handleVersionDialogClose() {
        setversionDialog(false)
        setSelectedRow(null)
    }

    function addAsset() {
        var fields = ''
        if (!name) {
            fields = "Name"
        }
        if (!assetType) {
            fields += !fields ? "Asset Type" : ", Asset Type"
        }
        if (fields) {
            enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            })
        } else {
            setButtonDisabled(true)
            let postData = {
                name: name,
                description: description,
                project: props.data.id,
                created_by: user.id,
                asset_type: assetType.id,
                asset_parameter: !fileName && !packageName ? null : {
                    open_file: fileName,
                    package_name: packageName
                }
            }
            Service.post(`/api/projects/asset/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    enqueueSnackbar('Asset Added Successfully', {
                        variant: 'success'
                    });
                    setButtonDisabled(false)
                    setAssetDialog(false)
                    setName('')
                    setDescription("")
                    setAssetType(null)
                    setFileName('')
                    setPackageName('')
                    getData()
                })
                .catch(error => {
                    console.log(error)
                    setButtonDisabled(false)
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        }
    }

    function editAsset() {
        var fields = ''
        if (!name) {
            fields = "Name"
        }
        if (!assetType) {
            fields += !fields ? "Asset Type" : ", Asset Type"
        }
        if (fields) {
            enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            })
        } else {
            setButtonDisabled(true)
            let putData = {
                name: name,
                description: description,
                project: props.data.id,
                created_by: user.id,
                asset_type: assetType.id,
                asset_parameter: !fileName && !packageName ? null : {
                    open_file: fileName,
                    package_name: packageName
                },
                is_active: active
            }
            Service.put('/api/projects/asset/' + selectedRow.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {
                    enqueueSnackbar('Asset edited Successfully', {
                        variant: 'success'
                    });
                    setButtonDisabled(false)
                    setAssetDialog(false)
                    setName('')
                    setDescription("")
                    setAssetType(null)
                    setFileName('')
                    setPackageName('')
                    setSelectedRow(null)
                    getData()
                })
                .catch(error => {
                    console.log(error)
                    setButtonDisabled(false)
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        }
    }

    function openEdit(data) {
        setMethodType('Edit')
        setName(data.name)
        setDescription(data.description)
        for (let i = 0; i < assets.length; i++) {
            const element = assets[i];
            if (element.id === data.asset_type) {
                setAssetType(element)
                break
            }
        }
        setFileName(data?.asset_parameter?.open_file)
        setPackageName(data?.asset_parameter?.package_name)
        setActive(data?.is_active)
        setSelectedRow(data)
        setAssetDialog(true)
    }

    const handleActive = (event) =>{
        setActive(event.target.value)
    }

    return (
        <div>
            <MaterialTable
                title={props.data?.name}
                isLoading={loading}
                data={tableData}
                columns={[
                    { title: 'Name', field: 'name', },
                    { title: 'Description', field: 'description', },
                    { title: 'Asset Type', field: 'asset_name' },
                    {
                        title: 'File Name', field: 'asset_parameter', editable: 'never',
                        render: rowData => rowData.asset_parameter?.open_file
                    },
                    {
                        title: 'Package Name', field: 'asset_parameter', editable: 'never',
                        render: rowData => rowData.asset_parameter?.package_name
                    },
                    {
                        title:'Active',field: 'is_active',lookup:{ true: 'Enabled', false: 'Disabled' } 
                    }
                ]}
                options={{
                    paging: false,
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    actionsColumnIndex: -1,
                    filtering: true,
                    detailPanelColumnStyle: { display: 'none' }
                }}
                actions={[
                    {
                        icon: () => <AddIcon />,
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => { setMethodType("Add"); setAssetDialog(true) }
                    },
                    // rowData => ({
                    //     icon: 'add',
                    //     tooltip: 'Add Versions',
                    //     disabled: rowData.configure_parameter ? rowData.asset_parameter === null : false,
                    //     onClick: () => { setSelectedRow(rowData); setversionDialog(true) }
                    // }),
                    {
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (e, rowData) => openEdit(rowData)
                    },
                ]}
                onRowClick={(event, rowData, toggleDetailPanel) => {
                    // toggleDetailPanel()
                setSelectedRow(rowData); setversionDialog(true)
                }}
                style={{ width: '100%' }}
            />


            <Dialog maxWidth="xs" fullWidth={true} open={assetDialog} onClose={handleClose} >
                <DialogTitle marginTop="2px" spacing={2}>{methodType + " "}Asset</DialogTitle>
                <DialogContent>
                    <Box display='flex' flexDirection={'column'} justifyContent={'center'}>
                        <Box mt={1}>
                            <TextField fullWidth autoComplete="off" value={name} variant="outlined" label="Name" onChange={(e) => { setName(e.target.value) }}></TextField>
                        </Box>
                        <Box mt={1}>
                            <TextField fullWidth autoComplete="off" value={description} variant="outlined" label="Description" onChange={(e) => setDescription(e.target.value)}></TextField>
                        </Box>
                        <Box mt={1}>
                            <Autocomplete
                                fullWidth
                                id="asset"
                                selectOnFocus={true}
                                options={assets}
                                value={assetType}
                                getOptionLabel={(option) => option['name'] ? option['name'] : ''}
                                isOptionEqualToValue={(option, value) => value && option.id === value.id}
                                renderInput={(params) => <TextField variant='outlined'  {...params} label="Asset type" />}
                                onChange={(event, values) => {
                                    if (values !== null) {
                                        setAssetType(values)
                                        setFileName('')
                                        setPackageName('')
                                    } else {
                                        setAssetType(null)
                                        setFileName('')
                                        setPackageName('')
                                    }
                                }}
                            />
                        </Box>
                        {
                            assetType && assetType.configure_parameter && assetType.os_platform === "windows" ?
                                <Box display='flex' flexDirection={'column'} justifyContent={'center'}>
                                    <Box mt={1}>
                                        <TextField fullWidth autoComplete="off" value={fileName} variant="outlined" label="File Name" onChange={(e) => { setFileName(e.target.value) }}></TextField>
                                    </Box>
                                </Box>
                                : null
                        }
                        {
                            assetType && assetType.configure_parameter && assetType.os_platform === "android" ?
                                <Box display='flex' flexDirection={'column'} justifyContent={'center'}>
                                    <Box mt={1}>
                                        <TextField fullWidth autoComplete="off" value={packageName} variant="outlined" label="Package Name" onChange={(e) => { setPackageName(e.target.value) }}></TextField>
                                    </Box>
                                </Box>
                                : null
                        }
                        <Box mt={1}>
                        <FormControl variant="outlined" fullWidth>
                        <InputLabel id="version_head">Active</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="version_head"
                            defaultValue={active}
                            label="Active"
                            onChange={handleActive}
                        >
                            <MenuItem value={true}>Enabled</MenuItem>
                            <MenuItem value={false}>Disabled</MenuItem>
                        </Select>
                    </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="text" onClick={handleClose}>Cancel</Button>
                    {methodType === "Add" ?
                        <Button disabled={buttonDisabled} color="primary" variant="text" onClick={() => addAsset()}>Add</Button> :
                        <Button disabled={buttonDisabled} color="primary" variant="text" onClick={() => editAsset()}>Edit</Button>}
                </DialogActions>

            </Dialog>

            <Dialog maxWidth="lg" fullWidth={true} open={versionDialog} onClose={handleVersionDialogClose} >
                <DialogTitle marginTop="2px" spacing={2}>{selectedRow?.name}</DialogTitle>
                <DialogContent>
                    <VersionTable data={selectedRow} project={props.data} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default withSnackbar(AssetTable)


