import React, { useEffect, useState } from 'react'
import { useSnackbar } from "notistack";
import { getCookie } from '../Utils/CookieHelper';
import Service from '../Networking/NetworkingUtils';
import { Box, Button, DialogActions, Grid, IconButton, Tooltip } from '@mui/material';
import { Card, DialogTitle, Dialog, TextField, InputAdornment, InputLabel, FormControl } from '@material-ui/core';
import VideoList from './OpenVideoList';
import { blobDomain, blobNoCacheDomain, blobSAS } from '../Helper/BlobHelper';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@mui/icons-material/Download';
import OpenAssetList from './OpenAssetList';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import '../App.css'
import axios from 'axios';
import OpenAppList from './OpenAppList';

const token = getCookie("bb_metaverse_token");
const dark = getCookie('theme_mode')

const OpenProjectStore = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([])
    const [dataCopy, setDataCopy] = useState([])
    const [videoDialog, setVideoDialog] = useState(false);
    const [appDialog, setAppDialog] = useState(false)
    const [videos, setVideos] = useState([])
    const [apps, setApps] = useState([])
    const [assets, setAssets] = useState([])
    const [projectName, setProjectName] = useState('')
    const [downloadDialog, setDownloadDialog] = useState(false)
    const [carousel, setCarousel] = useState(null)

    const getData = () => {
        Service.get('/api/projects/get_open_project/', {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                for (let index = 0; index < element.assets.length; index++) {
                    const asset = element.assets[index];
                    if (asset.asset_platform === "web_gl") {
                        element.is_webgl = true
                    }
                    if (asset.asset_platform === "video") {
                        element.is_video = true
                    }
                    if (asset.asset_platform === "download") {
                        element.is_download = true
                    }
                }
                if (!('is_webgl' in element)) {
                    element.is_webgl = false
                }
                if (!('is_video' in element)) {
                    element.is_video = false
                }
                if (!('is_download' in element)) {
                    element.is_download = false
                }
            }
            setData(res.data)
            setDataCopy(res.data)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        }
        )
    }

    const getCarouselData = async () => {
        try {
            const response = await axios.get(blobNoCacheDomain() + 'carousel/carousel.json?' + blobSAS());
            setCarousel(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getData()
        getCarouselData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleVideos = (id) => {
        setVideoDialog(true)
        let video = []
        let assest_data = data[id]
        for (let index = 0; index < assest_data.assets.length; index++) {
            if (assest_data.assets[index].asset_platform === "video")
                video.push(assest_data.assets[index])
        }
        setVideos(video)
    }

    const handleApp = (id) => {
        setAppDialog(true)
        let app = []
        let assest_data = data[id]
        for (let index = 0; index < assest_data.assets.length; index++) {
            if (assest_data.assets[index].asset_platform === "web_gl")
                app.push(assest_data.assets[index])
        }
        setApps(app)
    }

    const handleAssets = (id) => {
        setDownloadDialog(true)
        let asset = []
        let assest_data = data[id]
        for (let index = 0; index < assest_data.assets.length; index++) {
            if (assest_data.assets[index].asset_platform === "download")
                asset.push(assest_data.assets[index])
        }
        setAssets(asset)
    }

    const handleVideoDialog = () => {
        setVideoDialog(false)
    }

    const handleDownloadDialog = () => {
        setDownloadDialog(false)
    }

    const handleAppDialog = () => {
        setAppDialog(false)
    }

    const handleSearch = (data) => {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = dataCopy
        }

        for (let i = 0; i < dataCopy.length; i++) {
            const element = dataCopy[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.name.toLowerCase().includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        setData(filtered_data)
    }

    const cardComponent = (data, index) => {
        return (
            <Grid item xs={window.innerWidth <= 1650 ? 4 : 3} key={index} >
                <Card elevation={8} style={{ borderRadius: '10px', height: '320px' }}>
                    {/* <Box
                        component="img"
                        src={blobDomain() + data.project_thumbnail + "?" + blobSAS()}
                        sx={{ height: "100%", width: '100%' }}
                    /> */}
                    <img src={blobDomain() + data.project_thumbnail + "?" + blobSAS()} alt='' style={{ height: "200px", width: "100%", objectFit: 'cover' }} />
                    <Box sx={{ m: 1 }}>
                        <Box sx={{ mr: 1, fontWeight: "bold", fontSize: "large" }}>
                            {data.name}
                        </Box>
                        <Box sx={{ mt: 1, mr: 0.5, textOverflow: 'ellipsis', overFlow: 'hidden' }}>
                            {data.description}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                            {data.is_video ?
                                <Button size="small" variant="contained" sx={{ mr: 1 }} onClick={() => { handleVideos(index); setProjectName(data.name) }}>Play Video</Button>
                                : null}
                            {data.is_webgl ?
                                <Button size="small" variant="contained" sx={{ mr: 1 }} onClick={() => { handleApp(index); setProjectName(data.name) }}>Open App</Button>
                                : null}
                            {data.is_download ?
                                <Tooltip title="Downloadable Assets">
                                    <IconButton size="small" variant="contained" sx={{ mr: 1 }} onClick={() => { handleAssets(index); setProjectName(data.name) }} >
                                        <DownloadIcon sx={{ color: dark ? 'white' : null }} />
                                    </IconButton>
                                </Tooltip> : null}
                        </Box>
                    </Box>
                </Card>
            </Grid>
        )
    }

    return (
        <div>
            {carousel ?
                <div style={{ height: '40vh', width: "100%", marginBottom: "15px", }}>
                    <Carousel
                        className="carousel-wrapper"
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={3000} >
                        {
                            carousel.map((data, index) => {
                                return (
                                    <div key={index} style={{ height: "100%", width: "100%" }}>
                                        <img src={blobDomain() + "carousel/" + data + "?" + blobSAS()} alt={index + "img"} objectFit='cover' />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div> : null}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Box>
                    <h2>Project Store</h2>
                </Box>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <TextField
                        id="outlined-adornment-password"
                        label="Search"
                        size="small"
                        variant='outlined'
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        }}
                        labelWidth={70}
                    />
                </FormControl>
            </div>
            <div>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {data.map((data, index) => {
                            return (cardComponent(data, index))
                        })}
                    </Grid>
            </div>
            <Dialog fullWidth={true} maxWidth="sm" open={videoDialog} onClose={handleVideoDialog}>
                <DialogTitle>{projectName} - Videos</DialogTitle>
                <VideoList videos={videos} />
                <DialogActions>
                    <Button variant="contained" onClick={handleVideoDialog}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="sm" open={appDialog} onClose={handleAppDialog}>
                <DialogTitle>{projectName} - Apps</DialogTitle>
                <OpenAppList apps={apps} />
                <DialogActions>
                    <Button variant="contained" onClick={handleAppDialog}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="sm" open={downloadDialog} onClose={handleDownloadDialog}>
                <DialogTitle>{projectName} - Downloadable Assets</DialogTitle>
                <OpenAssetList assets={assets} projectName={projectName} />
                <DialogActions>
                    <Button variant="contained" onClick={handleDownloadDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default OpenProjectStore