import { withSnackbar } from 'notistack'
import React from 'react'
import { useSnackbar } from "notistack";
import { getCookie } from '../../Utils/CookieHelper';
import { useEffect } from 'react';
import MaterialTable from 'material-table';
import Service from '../../Networking/NetworkingUtils';
import Blob from '../../Blob';
import { DialogTitle, DialogContent, Dialog, DialogActions, Button, TextField, Select, InputLabel, FormControl, MenuItem, Box, List, ListItem, ListItemText, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloseIcon from '@mui/icons-material/Close';
import VersionPlanner from '../../Planner/VersionPlanner';
import { blobServiceClientFunc } from '../../Helper/BlobHelper';


var token = getCookie("bb_metaverse_token");
// var access = JSON.parse(getCookie("bb_metaverse_access"));
var user = JSON.parse(getCookie("bb_metaverse_user"))

const VersionTable = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(true)
    const [tableData, setTableData] = React.useState([])
    const [selectedRow, setSelectedRow] = React.useState(null)
    const [openUpload, setOpenUpload] = React.useState(false)
    const [versionNames, setVersionNames] = React.useState([])
    const [versionCodes, setVersionCodes] = React.useState(false)
    const [editOpen, setEditOpen] = React.useState(false)
    const [oldData, setOldData] = React.useState(null)
    const [newData, setNewData] = React.useState(null)
    const [editDisabled, setEditDisabled] = React.useState(false)
    const [addDisabled, setAddDisabled] = React.useState(false)
    const [addDialog, setAddDialog] = React.useState(false)
    const [filePath, setFilePath] = React.useState(null)
    const [filePathDialog, setFilePathDialog] = React.useState(false)
    const [plannerDialog, setPlannerDialog] = React.useState(false)
    const [version, setVersion] = React.useState({})
    const [deleteDialog, setDeleteDialog] = React.useState(false)
    const [deleteData, setDeleteData] = React.useState(null)
    const [accessList, setAccessList] = React.useState([])
    const [accessListObj, setAccessListObj] = React.useState({})

    useEffect(() => {
        getAccessList()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getAccessList() {
        setLoading(true)
        Service.get(`/api/projects/access/list/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                setAccessList(res.data)
                var obj = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    obj[element.id] = element.name
                }
                setAccessListObj(obj)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function getData() {
        setLoading(true)
        Service.get(`/api/asset/version/get_version_with_delete_status/?project_asset_id=${props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var versionNames = []
                var versionCodes = []

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element) {
                        versionNames.push(element.version)
                        versionCodes.push(element.version_code)
                    }
                }
                setVersionNames(versionNames)
                setVersionCodes(versionCodes)
                setTableData(res.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    const addVersion = (newData) => {
        if (!newData.version || newData.version_code <= 0 || !newData.version_head) {
            enqueueSnackbar('Version, Version Code, Version Head are mandatory fields!', {
                variant: 'warning'
            });
        } else if (versionNames.includes(newData.version.trim()) || versionCodes.includes(newData.version_code)) {
            var fields = ''
            if (versionNames.includes(newData.version.trim())) {
                fields = "Version"
            }
            if (versionCodes.includes(newData.version_code)) {
                fields += !fields ? "Version code" : ", Version code"
            }

            enqueueSnackbar(fields + ' is already exits!', {
                variant: 'warning'
            })
        } else {
            setLoading(true)
            setAddDisabled(true)
            let data = {
                // project: props.project.id,
                created_by: user.id,
                version: newData.version.trim(),
                version_code: Number(newData.version_code),
                project_asset: props.data.id,
                version_head: newData.version_head,
                asset_source: newData.asset_source,
                change_log: newData.change_log
            }
            Service.post('/api/asset/version/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            }).then(res => {
                getData()
                enqueueSnackbar('version Added Successfully!', {
                    variant: 'success'
                })
                setAddDisabled(false)
                setAddDialog(false)
            }).catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
        }
    }

    const editVersion = () => {
        if (!newData.version || parseInt(newData.version_code) <= 0 || !newData.version_head) {
            enqueueSnackbar('Version, Version Code, Version Head are mandatory fields!', {
                variant: 'warning'
            });
        } else if ((newData.version !== oldData.version) && (versionNames.includes(newData.version.trim()))) {
            enqueueSnackbar('Version is already exits!', {
                variant: 'warning'
            })
        } else if ((parseInt(newData.version_code) !== oldData.version_code) && (versionCodes.includes(parseInt(newData.version_code)))) {
            enqueueSnackbar('Version code is already exits!', {
                variant: 'warning'
            })
        } else {
            setEditDisabled(true)
            setLoading(true)
            let data = {
                project: props.project.id,
                created_by: user.id,
                version: newData.version.trim(),
                version_code: parseInt(newData.version_code),
                project_asset: props.data.id,
                version_head: newData.version_head,
                asset_source: newData.asset_source,
                change_log: newData.change_log
            }
            Service.put('/api/asset/version/' + newData.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            }).then(res => {
                getData()
                setNewData(null)
                setOldData(null)
                setEditOpen(false)
                enqueueSnackbar('version edited Successfully!', {
                    variant: 'success'
                })
                setEditDisabled(false)
            }).catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                setEditDisabled(false)
            })
        }

    }

    const handleClose = () => {
        setOpenUpload(false)
    }

    const UpdateBlob = (data, row) => {
        if (row && data.length > 0) {
            row.asset_source = data.join()
            Service.put('/api/asset/version/' + row.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: row
            }).then(res => {
                getData()
                enqueueSnackbar('blob uploaded sucessfully!', {
                    variant: 'success'
                })
                setOpenUpload()
            }).catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
        }
    }

    const deleteFiles = async (oldData) => {
        setLoading(true)

        const blobServiceClient = blobServiceClientFunc();

        const containerName = "project-executables/BBVPROJ" + props.project.id + "/ASSET" + props.data.id;

        const containerClient = blobServiceClient.getContainerClient(containerName);
        if (oldData.asset_source) {
            var files = oldData.asset_source.split(',')

            for (let i = 0; i < files.length; i++) {
                const element = files[i];
                if (element) {
                    let file_name = element.split('/').pop()
                    try {
                        await containerClient.deleteBlob(file_name);
                    } catch (error) {
                        if (error.statusCode === 404) {
                            console.log("Blob not found")
                            enqueueSnackbar('Blob not found', {
                                variant: 'error'
                            })
                        } else {
                            console.log(error)
                        }
                        // enqueueSnackbar('Something went wrong!', {
                        //     variant: 'error'
                        // })
                    }
                }
            }
        }
        deleteVersion(oldData)
    };

    const deleteVersion = (oldData) => {
        setLoading(true)
        Service.delete('/api/asset/version/' + oldData.id +"/", {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setDeleteDialog(false)
            getData()
            enqueueSnackbar('version deleted Successfully!', {
                variant: 'success'
            })
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        })
    }

    const handleVersionHeadChange = (event) => {
        setNewData({ ...newData, ...{ [event.target.id]: event.target.value } })
    };

    const handleVersionChange = (event) => {
        setNewData({ ...newData, ...{ 'version_head': event.target.value } })
    };

    const closeEditDialog = () => {
        setNewData(null)
        setOldData(null)
        setEditOpen(false)
    }

    const handleAddDialog = () => {
        setAddDialog(false)
    }

    const handleFilePath = (filepath) => {
        setFilePathDialog(true)
        if (filepath !== null) {
            let asset = filepath.split(",")
            setFilePath(asset)
        }
    }

    const handleFilePathDialog = () => {
        setFilePathDialog(false)
        setFilePath(null)
    }

    return (
        <div>
            <MaterialTable
                title=''
                isLoading={loading}
                data={tableData}
                columns={[
                    {
                        title: 'Version', field: 'version', defaultSort: 'asc'
                    },
                    {
                        title: 'Version Code', field: 'version_code', type: 'numeric',
                    },
                    { title: 'Version Head', field: 'version_head', lookup: accessListObj },
                    { title: 'Latest Change', field: 'change_log' }
                    // { title: 'Asset Source', field: 'asset_source', },
                ]}
                options={{
                    paging: false,
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    actionsColumnIndex: -1,
                    filtering: true,
                    search: false,
                    addRowPosition: 'first'
                    // toolbar: false
                }}
                actions={[
                    rowData => ({
                        icon: 'upload',
                        tooltip: 'Upload',
                        disabled: rowData.asset_source,
                        onClick: () => { setSelectedRow(rowData); setOpenUpload(true) }
                    }),
                    rowData => ({
                        icon: 'edit',
                        tooltip: 'Edit',
                        // disabled: rowData.asset_source,
                        onClick: () => { setNewData(rowData); setOldData(rowData); setEditOpen(true) }
                    }),
                    {
                        icon: () => <AddIcon />,
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => { setAddDialog(true) }
                    },
                    rowData => ({
                        icon: () => <VisibilityIcon />,
                        tooltip: 'View File Path',
                        isFreeAction: true,
                        onClick: () => { handleFilePath(rowData.asset_source) }

                    }),
                    rowData => ({
                        icon: () => <DashboardIcon />,
                        tooltip: 'View Planner',
                        isFreeAction: true,
                        onClick: () => { setPlannerDialog(true); setVersion(rowData) }
                    })
                ]}
                editable={{
                    // isEditable: rowData => !rowData.asset_source,
                    // onRowAdd: newData =>
                    //     new Promise((resolve, reject) => {
                    //         if (!newData.version || newData.version_code <= 0 || !newData.version_head) {
                    //             enqueueSnackbar('Version, Version Code, Version Head are mandatory fields!', {
                    //                 variant: 'warning'
                    //             });
                    //             resolve();
                    //         } else if (versionNames.includes(newData.version.trim()) || versionCodes.includes(newData.version_code)) {
                    //             var fields = ''
                    //             if (versionNames.includes(newData.version.trim())) {
                    //                 fields = "Version"
                    //             }
                    //             if (versionCodes.includes(newData.version_code)) {
                    //                 fields += !fields ? "Version code" : ", Version code"
                    //             }

                    //             enqueueSnackbar(fields + ' is already exits!', {
                    //                 variant: 'warning'
                    //             })
                    //             resolve();
                    //         } else {
                    //             setTimeout(() => {
                    //                 setTableData([...tableData, newData]);
                    //                 addVersion(newData)
                    //                 resolve();
                    //             }, 1000)
                    //         }
                    //     }),
                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise((resolve, reject) => {
                    //         if (!newData.version || newData.version_code <= 0 || !newData.version_head) {
                    //             enqueueSnackbar('Version, Version Code, Version Head are mandatory fields!', {
                    //                 variant: 'warning'
                    //             });
                    //             resolve();
                    //         } else if ((newData.version !== oldData.version) && (versionNames.includes(newData.version.trim()))) {
                    //             enqueueSnackbar('Version is already exits!', {
                    //                 variant: 'warning'
                    //             })
                    //             resolve();
                    //         } else if ((newData.version_code !== oldData.version_code) && (versionCodes.includes(newData.version_code))) {
                    //             enqueueSnackbar('Version code is already exits!', {
                    //                 variant: 'warning'
                    //             })
                    //             resolve();
                    //         } else {
                    //             setTimeout(() => {
                    //                 const dataUpdate = [...tableData];
                    //                 const index = oldData.tableData.id;
                    //                 dataUpdate[index] = newData;
                    //                 editVersion(dataUpdate[index])
                    //                 resolve();
                    //             }, 1000)
                    //         }
                    //     }),
                    isDeletable: rowData => rowData.is_deletable,
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                console.log(oldData)
                                if (oldData.is_deletable) {
                                    deleteFiles(oldData)
                                } else {
                                    setDeleteData(oldData)
                                    setDeleteDialog(true)
                                }
                                resolve()
                            }, 1000)
                        }),
                }}
                style={{ width: '100%' }}
            />


            <Dialog maxWidth="lg" fullWidth={true} open={openUpload}  >
                <DialogTitle marginTop="2px" spacing={2}>Upload Blob</DialogTitle>
                <DialogContent>
                    <Blob
                        containerName={"project-executables/BBVPROJ" + props.project?.id + "/ASSET" + props.data?.id}
                        fileName={props.project?.id + "_" + props.data?.id + "_" + selectedRow?.id}
                        project={props.project}
                        asset={props.data}
                        filesType={props.data?.files_type}
                        version={selectedRow}
                        onClose={handleClose}
                        updateBlob={UpdateBlob} />
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="xs" fullWidth={true} open={editOpen}  >
                <DialogTitle marginTop="2px" spacing={2}>Edit</DialogTitle>
                <DialogContent>
                    {!oldData?.asset_source ? <>
                        <TextField
                            id='version'
                            fullWidth
                            autoComplete="off"
                            variant="outlined"
                            label="Version"
                            onChange={handleVersionHeadChange}
                            defaultValue={newData?.version}
                            style={{ marginBottom: "10px" }} />
                        <TextField
                            type='number'
                            id='version_code'
                            fullWidth
                            autoComplete="off"
                            variant="outlined"
                            label="Version Code"
                            onChange={handleVersionHeadChange}
                            defaultValue={newData?.version_code}
                            style={{ marginBottom: '10px' }} />
                    </> : null}
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="version_head">Version Head</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="version_head"
                            defaultValue={newData?.version_head}
                            label="Version Head"
                            onChange={handleVersionChange}
                        >
                            {accessList.map((data, index) => {
                                return (
                                    <MenuItem value={data.id}>{data.name}</MenuItem>
                                )
                            })}
                            {/* // <MenuItem value={'stable'}>Stable</MenuItem>
                            // <MenuItem value={'alpha'}>Alpha</MenuItem>
                            // <MenuItem value={'beta'}>Beta</MenuItem> */}
                        </Select>
                    </FormControl>
                    <TextField
                        id='change_log'
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        label="Latest Change"
                        onChange={handleVersionHeadChange}
                        defaultValue={newData?.change_log}
                        style={{ marginTop: "10px" }} />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={closeEditDialog} size='small'>
                        Close
                    </Button>
                    <Button disabled={editDisabled} color='primary' variant='contained' onClick={editVersion} size='small'>
                        Edit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth={true} open={addDialog} onClose={handleAddDialog}>
                <DialogTitle marginTop="2px" spacing={2}>Add Version</DialogTitle>
                <DialogContent>
                    <TextField
                        id='version'
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        label="Version"
                        onChange={handleVersionHeadChange}
                        // defaultValue={newData?.version}
                        style={{ marginBottom: "10px" }} />
                    <TextField
                        id='version_code'
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        label="Version Code"
                        onChange={handleVersionHeadChange}
                        // defaultValue={newData?.version}
                        style={{ marginBottom: "10px" }} />
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="version_head">Version Head</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="version_head"
                            // defaultValue={newData?.version_head}
                            label="Version Head"
                            onChange={handleVersionChange}
                        >
                            {accessList.map((data, index) => {
                                return (
                                    <MenuItem value={data.id}>{data.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        id='change_log'
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        label="Latest Change"
                        onChange={handleVersionHeadChange}
                        // defaultValue={newData?.version}
                        style={{ marginTop: "10px" }} />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={handleAddDialog} size='small'>
                        Close
                    </Button>
                    <Button disabled={addDisabled} color='primary' variant='contained' onClick={() => addVersion(newData)} size='small'>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth="sm" fullWidth={true} open={filePathDialog} onClose={handleFilePathDialog}>
                <DialogTitle marginTop="2px" spacing={2}>File Path</DialogTitle>
                <DialogContent>
                    <Box>
                        {filePath === null ?
                            <Box mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Files are available</Box> :
                            <List>
                                {filePath.map((data, index) => {
                                    return (
                                        <ListItem>
                                            <ListItemText
                                                primary={data}
                                            />
                                        </ListItem>
                                    )
                                })
                                }
                            </List>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={handleFilePathDialog} size='small'>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog open={plannerDialog} fullScreen>
                <AppBar sx={{ position: 'relative', color: 'primary' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setPlannerDialog(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Version Planner
                        </Typography>
                    </Toolbar>
                </AppBar>
                <VersionPlanner version={version} />
            </Dialog>
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)} >
                <DialogTitle>Delete Version</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this version?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} variant='contained' color="primary">Cancel</Button>
                    <Button onClick={() => deleteFiles(deleteData)} variant='contained' color="primary">Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withSnackbar(VersionTable)


