import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import App from './App';
import ReactBreakpoints from 'react-breakpoints';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ThemeProviderV5 from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import reportWebVitals from './reportWebVitals';
import { getDarkMode, getPrimaryColor, getSecondaryColor } from './Helper/CommonThings';



const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: getPrimaryColor(),
    },
    secondary: {
      main: getSecondaryColor(),
    },
    type: getDarkMode(),
  }
})

const bbColorThemeV5 = createTheme({
  palette: {
    primary: {
      main: getPrimaryColor(),
    },
    secondary: {
      main: getSecondaryColor(),
    },
    mode: getDarkMode()
  }
})

console.log(window.location.pathname)

ReactDOM.render(

  <SnackbarProvider>
    <ReactBreakpoints breakpoints={breakpoints} >
      <ThemeProvider theme={bbColorTheme}>
        <ThemeProviderV5 theme={bbColorThemeV5}>
          <CssBaseline />
          <App />
        </ThemeProviderV5>
      </ThemeProvider>
    </ReactBreakpoints>
  </SnackbarProvider>, document.getElementById('root'));

//  window.$apilink = 'https://dcp-phase-i.appspot.com' //global variable

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// make the whole serviceworker process into a promise so later on we can
// listen to it and in case new content is available a toast will be shown

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          if (
            window.confirm(
              "There is a new version of the app ready to update. Please click ok to update instantly."
            )
          ) {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  },
})

reportWebVitals();

// Update:
// this also can be incorporated right into e.g. your run() function in angular,
// to avoid using the global namespace for such a thing.
// because the registering of a service worker doesn't need to be executed on the first load of the page.


