import { Box, List, ListItem, ListItemButton } from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { blobDomain } from '../Helper/BlobHelper'
import { blobSAS } from '../Helper/BlobHelper'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Dialog, IconButton, ListItemText } from '@material-ui/core'


const VideoList = (props) => {

    const [url, setUrl] = useState(null);
    const [videoDialog, setVideoDialog] = useState(false)

    const handleVideoDialog = () => {
        setVideoDialog(false)
    }

    return (
        <div>
            <div>
                <List dense={true}>
                    {props.videos.map((data, index) => {
                        return (
                            <ListItemButton onClick={() => { setVideoDialog(true); setUrl(data.asset_source);}}>
                                <ListItem
                                   
                                >
                                    <ListItemText
                                        primary={data.name}
                                        secondary={data.description ? data.description: null}
                                    />
                                </ListItem>
                            </ListItemButton>
                        )
                    })}
                </List>
            </div>
            <Dialog fullWidth={true} maxWidth="md" open={videoDialog} onClose={handleVideoDialog}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ m: 2 }}>
                        <ReactPlayer
                            // width="100%" 
                            url={blobDomain() + url + "?" + blobSAS()}
                            controls={true} pip={true} stopOnUnmount={false}
                            width="500" height="500"
                            // light={value.thumbnail}
                            playing
                            playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        onContextMenu: e => e.preventDefault()
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default VideoList