import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { Button, DialogTitle, TextField, DialogContent, LinearProgress, AppBar, Dialog } from '@material-ui/core';
import { Toolbar, DialogActions, Tooltip} from '@mui/material';
import moment from 'moment';
import { useSnackbar, withSnackbar } from "notistack";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import UserAccess from './UserAccess';
import Slide from '@mui/material/Slide';
import DescriptionIcon from '@mui/icons-material/Description';
import Assets from './Assets';
import AssetTable from './Assets/AssetTable';
import UploadIcon from '@mui/icons-material/Upload';
import { DropzoneArea } from 'material-ui-dropzone';
import { blobWriteString } from '../Helper/BlobHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var token = getCookie("bb_metaverse_token");
var access = JSON.parse(getCookie("bb_metaverse_access"));
// var user = JSON.parse(getCookie('bb_metaverse_user'))

const Row = (props) => {
  const { row } = props;
  const [fulldialogopen, setFulldialogopen] = React.useState(false);
  const [editdialogopen, setEditdialogopen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = React.useState({});
  const [editProjDisable, setEditProjDisable] = React.useState(false)
  const [accessDialog, setAccessDialog] = React.useState(false)
  const [isProjSegment, setIsProjSegment] = React.useState(false)
  const [assetDialog, setAssetDialog] = React.useState(false)
  const [project, selectedProject] = React.useState(null)
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [thumbnailUploadDialog, setThumbnailUploadDialog] = React.useState(false)
  const [files, setFiles] = React.useState(null)
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  function Open(row) {
    if (row !== null) {
      selectedProject(row)
      setFulldialogopen(true)
    }
  }

  function Editfunc(row) {
    setSelectedRow(row)
    setEditdialogopen(true)
  }

  // function uploadData() {
  //   // setDisabled(true)
  //   towerUploadRef.current.postData()
  // }

  function handleTextChange(event) {
    setSelectedRow({ ...selectedRow, ...{ [event.target.id]: event.target.value } })
  }

  function submitEditProject() {
    if (!selectedRow['name'] || !selectedRow['description']) {
      enqueueSnackbar('Fields are not completely filled', {
        variant: 'warning'
      })
    }
    else {
      editData(selectedRow)
    }
  }

  function editData(selectedRow) {
    setEditProjDisable(true)
    Service.put('/api/projects/' + selectedRow.id + '/', {
      headers: {
        Authorization: "Token " + token,
      },
      data: selectedRow
    }).then(res => {
      enqueueSnackbar('Data added successfully!', {
        variant: 'success'
      })
      setEditdialogopen(false)
      setEditProjDisable(false)
      props.getProject()
    }).catch(error => {
      setEditProjDisable(false)
      console.log(error);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      })
    })
  }

  function UpdateThumbnail(thumbnail, rowData) {
    rowData.project_thumbnail = thumbnail
    Service.put('/api/projects/' + selectedRow.id + '/', {
      headers: {
        Authorization: "Token " + token,
      },
      data: selectedRow
    }).then(res => {
      enqueueSnackbar('Thumbnail added successfully!', {
        variant: 'success'
      })
      setSelectedRow(null)
      setThumbnailUploadDialog(false)
      props.getProject()
    }).catch(error => {
      console.log(error);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      })
    })
  }

  // const updateDisabled = (value) => {
  //   setDisabled(value)
  // }

  // const getData = () => {
  //   dataDisplayRef.current.getData()
  // }

  // const handleFailureData = (childData) => {
  //   // setFailureData(childData)
  //   // setFailureDataDialog(true)
  // }

  const handleOpen = (row) => {
    setAccessDialog(true)
    setSelectedRow(row)
    setIsProjSegment(false)
  }

  const handleAccessDialog = () => {
    setAccessDialog(false)
  }

  // const openAddLink = (row) => {
  //   // setLinkDialog(true)
  //   setProject(row)
  //   console.log(row)
  // }

  const closeAssetDialog = () => {
    setAssetDialog(false)
  }

  const handleAssetDialog = (row) => {
    setSelectedRow(row)
    setAssetDialog(true)
  }

  const handleUploadDialog = () => {
    setSelectedRow(null)
    setThumbnailUploadDialog(false)
    setSubmitDisabled(true)
  }

  const uploadFiles = async () => {
    const { BlobServiceClient } = require("@azure/storage-blob");
    window.Buffer = require("buffer").Buffer;

    const blobSasUrl = blobWriteString()
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    const containerName = "project-executables/BBVPROJ" + selectedRow.id + "/Thumbnails";

    const containerClient = blobServiceClient.getContainerClient(containerName);

    try {
      const promises = [];
      setLoading(true)
      for (const file of files) {
        var file_extension = file.name.split('.').pop()
        var file_name = selectedRow.id + "." + file_extension
        const blockBlobClient = containerClient.getBlockBlobClient(file_name);
        promises.push(blockBlobClient.uploadData(file, {
          onProgress: ev => {
            setProgress((ev.loadedBytes / file.size) * 100)
          }
        }));
      }
      await Promise.all(promises);
      setLoading(false)
      UpdateThumbnail(containerName + "/" + file_name, selectedRow)
      // this.props.updateBlob(file_name_array, this.props.version)
    }
    catch (error) {
      console.log(error.message);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      });
    }
  }

  return (
    <React.Fragment>
      <TableRow onClick={() => Open(row)} style={{ cursor: 'pointer' }}>
        <TableCell component="th" scope="row" align="left" >
          {row?.name}
        </TableCell>
        <TableCell align="left">{row?.description}</TableCell>
        {/* <TableCell align="left">{row?.project_thumbnail}</TableCell> */}
        <TableCell align="left">{moment(row?.date_created).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="left">{row?.last_name}{", "}{row?.first_name}</TableCell>
        <TableCell align="left" style={{ width: 100 }} onClick={(event) => { if (event) { event.stopPropagation(); } }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {(row?.project_admin) ||
              (access && access.access && access.access.l0a.l0a_b) ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Tooltip title="Upload Thumbnail">
                  <UploadIcon style={{ cursor: "pointer" }} onClick={(event) => { event.stopPropagation(); setSelectedRow(row); setThumbnailUploadDialog(true) }} />
                </Tooltip>
                <Tooltip title="Project Assets">
                  <DescriptionIcon sx={{ ml: 1, cursor: "pointer" }} onClick={(event) => { event.stopPropagation(); handleAssetDialog(row) }} />
                </Tooltip>
                <Tooltip title="Access for Users">
                  <GroupAddIcon sx={{ ml: 1, cursor: "pointer" }} onClick={(event) => { event.stopPropagation(); handleOpen(row) }} />
                </Tooltip>
                <Tooltip title="Edit Project">
                <EditIcon onClick={() => Editfunc(row)} style={{ fontSize: "sm", cursor: "pointer" }} />
                </Tooltip>
              </div> : null}
          </div>
        </TableCell>
      </TableRow>


      <Dialog
        fullScreen
        open={fulldialogopen}
        TransitionComponent={Transition}
      >
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setFulldialogopen(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {project?.name}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <AssetTable data={project} />
        </DialogContent>
      </Dialog>

      <Dialog maxWidth="xs" fullWidth={true} open={editdialogopen} TransitionComponent={Transition}>
        <DialogTitle>Edit Project </DialogTitle>
        <DialogContent style={{ gap: "12px", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
          <TextField fullWidth autoComplete="off" defaultValue={selectedRow?.name} variant="outlined" label="Name" id="name" onChange={handleTextChange}></TextField>
          <TextField fullWidth autoComplete="off" defaultValue={selectedRow?.description} variant="outlined" label="Description" id="description" onChange={handleTextChange}></TextField>
          {/* <TextField fullWidth multiline minRows={3} autoComplete="off" defaultValue={selectedRow?.project_thumbnail} variant="outlined" label="Project Thumbnail" id="project_thumbnail" onChange={handleTextChange}></TextField> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setEditdialogopen(false)}>Cancel</Button>
          <Button disabled={editProjDisable} color="primary" onClick={() => submitEditProject()}>Edit</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
        open={accessDialog}
        onClose={handleAccessDialog}
      >
        <Box sx={{ m: 2 }}>
          <UserAccess project={selectedRow}
            isProjSegment={isProjSegment} />
        </Box>
      </Dialog>

      <Dialog
        fullScreen
        open={assetDialog}
        onClose={closeAssetDialog}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeAssetDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Asset
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ m: 2 }}>
          <Assets row={selectedRow} />
        </Box>
      </Dialog>

      <Dialog
        maxWidth="lg"
        fullWidth={true}
        TransitionComponent={Transition}
        open={thumbnailUploadDialog}
        onClose={handleUploadDialog}>
        <DialogTitle>Upload Thumbnail</DialogTitle>
        <DialogContent>
          <DropzoneArea
            filesLimit={1}
            maxFileSize={10737418240}
            onChange={(files) => setSubmitDisabled(!(files.length > 0))}
            onDrop={(files) => setFiles(files)}
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={() => handleUploadDialog()}>
            close
          </Button>
          <Button disabled={submitDisabled} color='primary' variant='contained' onClick={() => uploadFiles()}>
            submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="lg" fullWidth={true} open={loading}  >
        <DialogTitle marginTop="2px" spacing={2}>Uploading...</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="primary">{`${Math.round(
                progress,
              )}%`}</Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

    </React.Fragment >
  )
}

export default withSnackbar(Row)

