import { getEnvType } from "../Networking/NetworkingUtils"

export function blobDomain() {
    if (getEnvType() === "qa") {
        return 'https://bbversestorageqa-f8dkbkcudfadauge.z01.azurefd.net/'
    }
    return 'https://bbverseblobdev-hmgaeqaud0ekgrcs.z01.azurefd.net/'
}

export function blobNoCacheDomain() {
    if (getEnvType() === "qa") {
        return 'https://bbversestorageqa.blob.core.windows.net/'
    }
    return "https://bbverseblobdev.blob.core.windows.net/"
}

export function blobSAS() {
    if (getEnvType() === "qa") {
        return 'sv=2022-11-02&ss=bfqt&srt=co&sp=rtfx&se=2024-12-30T18:58:35Z&st=2024-07-25T10:58:35Z&spr=https&sig=Ra8cBWidT7BxXqD57j3hDTcneLxMy1v5vqHAXzGJ1fA%3D'
    }
    return 'sv=2022-11-02&ss=bf&srt=o&sp=r&se=2024-12-30T20:23:58Z&st=2024-08-13T12:23:58Z&spr=https,http&sig=RwUwkAi71F4xJdZhNMVeO67QcFbRgdx%2Fav2szOYIjyE%3D'
}

export function blobWriteString() {
    if (getEnvType() === "qa") {
        return blobNoCacheDomain() + '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2024-12-30T19:07:30Z&st=2024-07-25T11:07:30Z&spr=https,http&sig=FEo9aJo5fDQn59SJYgoh8gfKHsMbY1fCstZUV0ClccU%3D'
    }
    return blobNoCacheDomain() + "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-30T20:16:39Z&st=2024-04-01T01:16:39Z&spr=https,http&sig=6VKWJm1Pett7HzmIz%2Fh7Q8%2FFR%2F6k1A8VMwuC7vAyu6s%3D"
}

export function handleCacheControl(url) {

    if (url.match(/\.wasm/) || url.match(/\.data/) || url.match(/\.bundle/)) {

        // return "must-revalidate";
        return 'immutable'

    }

    if (url.match(/\.mp4/) || url.match(/\.wav/)) {

        return "immutable";

    }

    return "no-store";

}

export function blobServiceClientFunc() {
    if (getEnvType() === "prod") {
        return blobServiceClientFuncForPROD()
    } else if (getEnvType() === "qa") {
        return blobServiceClientForDEV()
    } else {
        return blobServiceClientFuncForDev()
    }
}

// function blobServiceClientFuncForQA() {
//     const { BlobServiceClient } = require("@azure/storage-blob");
//     const { InteractiveBrowserCredential } = require("@azure/identity");
//     window.Buffer = require("buffer").Buffer;

//     const blobSasUrl = blobWriteString()

//     const blobServiceClient = new BlobServiceClient(blobSasUrl, new InteractiveBrowserCredential(
//         {
//             tenantId: "a04222fe-0c5c-40bb-8420-97a219ba514e",
//             clientId: "64073365-5a03-4736-b34e-597376266a0c"
//         }
//     ));
//     return blobServiceClient
// }

function blobServiceClientFuncForDev() {
    const { BlobServiceClient } = require("@azure/storage-blob");
    const { InteractiveBrowserCredential } = require("@azure/identity");
    window.Buffer = require("buffer").Buffer;

    const blobSasUrl = blobWriteString()

    const blobServiceClient = new BlobServiceClient(blobSasUrl, new InteractiveBrowserCredential(
        {
            tenantId: "a04222fe-0c5c-40bb-8420-97a219ba514e",
            clientId: "64073365-5a03-4736-b34e-597376266a0c"
        }
    ));
    return blobServiceClient
}

function blobServiceClientFuncForPROD() {
    const { BlobServiceClient } = require("@azure/storage-blob");
    const { InteractiveBrowserCredential } = require("@azure/identity");
    window.Buffer = require("buffer").Buffer;

    const blobSasUrl = blobWriteString()

    const blobServiceClient = new BlobServiceClient(blobSasUrl, new InteractiveBrowserCredential(
        {
            tenantId: "a04222fe-0c5c-40bb-8420-97a219ba514e",
            clientId: "64073365-5a03-4736-b34e-597376266a0c"
        }
    ));
    return blobServiceClient
}

export function blobServiceClientForDEV() {
    const { BlobServiceClient } = require("@azure/storage-blob");
    window.Buffer = require("buffer").Buffer;

    const blobSasUrl = blobWriteString()

    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    return blobServiceClient
}