import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { getCookie } from "../Utils/CookieHelper";


var countTimes = 0;

export function getCountTime() {
    return countTimes
}

export function increseCountTime() {
    return ++countTimes
}

export function resetCountTime(path) {

    if (path === '/bb/api/token/access/') {
        return countTimes
    } else {
        countTimes = 0
        return 0;
    }

}

export function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

export function poundFormat(data) {
    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data)
}

var darkMode = getCookie('theme_mode')

if (!darkMode) {
    darkMode = localStorage.getItem("theme_mode")
}

export function getDarkMode() {
    if (darkMode) {
        return darkMode
    } else {
        return 'light'
    }

}

export function onlyUniqueArrId(value, index, self) {
    return self.indexOf(value.id) === index;
}

export function onlyUniqueArr(value, index, self) {
    return self.indexOf(value) === index;
}

export function comparer(otherArray) {
    return function (current) {
        return otherArray.filter(function (other) {
            return other === current
        }).length === 0;
    }
}


export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


export function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}


export function getPrimaryColor() {
    return '#007681'
}

export function getSecondaryColor() {
    return '#EEBE86'
}

export function getGBPFormat(val) {
    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(val)
}

export function getWbsType() {
    return {
        'ohl': 'OHL', 'cabling': 'Cabling', 'sub_struc': 'Substation Primary', 'ohl_civ_struc': 'Substation Secondary', 'sub_civ_struct': 'Substation Civ Structure', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC'
    }
}

export function jsonConcat(o1, o2) {
    for (var key in o2) {
        o1[key] = o2[key];
    }
    return o1;
}

export function getMode() {
    if (darkMode === 'dark') {
        return true
    } else {
        return false
    }
}

export function getGreencolor() {
    if (darkMode === 'dark') {
        return '#00b371'
    } else {
        return '#008755'
    }
}

export function getOrangecolor() {
    if (darkMode === 'dark') {
        return '#e7a356'
    } else {
        return '#e29236'
    }
}

export function getProgBgColor() {
    if (darkMode === 'dark') {
        return '#bebebe'
    } else {
        return '#e6e6e6'
    }
}