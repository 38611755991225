import React from 'react';
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Service from '../Networking/NetworkingUtils';
import { withSnackbar } from 'notistack';
import { getCookie } from '../Utils/CookieHelper';
import UserPermission from './UserPermission';
// import UserPermission from './UserPermission';

const token = getCookie("bb_metaverse_token");
//const access = JSON.parse(getCookie("bb_cdm_access"));

class UserAccess extends React.Component {
    constructor() {
        super()
        this.state = {
            userAddModal: false,
            first_name: '',
            last_name: '',
            email: '',
            isLoading: false,
            disabled: false
        }
        this.closeUserAddModal = this.closeUserAddModal.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        this.setState({ isLoading: true })
        Service.get('/api/users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            });
    }

    putOps(data) {
        this.setState({ isLoading: true })
        Service.put('/api/users/' + data.id+"/", {
            headers: {
                Authorization: "Token " + token,
            },
            data,
        })
            .then(res => {
                this.fetchOps()
                this.props.enqueueSnackbar('Successfully Updated', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false })
            });
    }

    closeUserAddModal() {
        this.setState({ userAddModal: false })
    }

    handleChange(e) {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }

    addUser() {
        if (this.state.first_name === '' || this.state.last_name === '' || this.state.email === '') {
            this.props.enqueueSnackbar('All the fields are required.', {
                variant: 'warning',
            });
        }
        else if (!this.state.email.includes("@balfourbeatty.com")) {
            this.props.enqueueSnackbar('Only Balfour Beatty email address is allowed.', {
                variant: 'warning',
            });
        }
        else {
            var json = {
                email: this.state.email.toLowerCase(),
                email_username: this.state.first_name + "." + this.state.last_name,
                first_name: this.state.first_name,
                last_name: this.state.last_name
            }
            this.setState({ isLoading: true, disabled: true })
            Service.post('/api/users/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: json,
            })
                .then(res => {
                    this.setState({ userAddModal: false, isLoading: false, disabled: false  }, () => {
                        this.setPassword(res.data.id)
                    });
                    this.props.enqueueSnackbar(json.email + ' is created', {
                        variant: 'success',
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    console.log(error)
                    if (error.data.email) {
                        this.props.enqueueSnackbar(error.data.email[0], {
                            variant: 'warning',
                        });
                    } else if (error.data.password1) {
                        this.props.enqueueSnackbar(error.data.password1[0], {
                            variant: 'warning',
                        });
                    } else {
                        this.setState({ isLoading: false, disabled: false  });
                        this.props.enqueueSnackbar('Something went wrong!. Try after sometime.', {
                            variant: 'error',
                        });
                    }
                });
        }
    }

    setPassword(id) {
        Service.get(`/api/users/password_change/?id=${id}`, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
               // console.log("done")
                // this.fetchOps();
            })
            .catch(error => {
                console.log(error)
                console.log("error")
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={'User Management'}
                    columns={[
                        { title: 'Id', field: 'id', defaultSort: 'asc', editable: 'never' },
                        { title: 'First Name', field: 'first_name' },
                        { title: 'Last Name', field: 'last_name' },
                        { title: 'Email', field: 'email', editable: 'never' },
                        { title: 'Active Status', field: 'is_active', lookup: { false: 'Inactive', true: 'Active' }, },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        pageSize: 20,
                        paging: false,
                        pageSizeOptions: [10, 20, 50, 100],
                        headerStyle: {
                            fontWeight: 'bold',
                        },
                        actionsColumnIndex: -1,
                        filtering: true
                    }}
                    detailPanel={
                        rowData => {
                            return (
                                 <UserPermission value={rowData} />
                            )
                        }
                    }
                    editable={{
                        
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.putOps(newData)
                                    resolve();
                                }, 1000)
                            }),
                    }}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add User',
                            isFreeAction: true,
                            onClick: (event) => {
                                this.setState({ userAddModal: true })
                            }
                        }
                   ]}
                />

                <Dialog
                    open={this.state.userAddModal}
                    onClose={this.closeUserAddModal}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title" >Add User</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeUserAddModal}
                            color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={this.addUser.bind(this)}
                            color="primary"
                            disabled={this.state.disabled}
                            >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(UserAccess);