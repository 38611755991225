import React from 'react'
import ChangePassword from './Home/ChangePassword'
import {  Card } from '@material-ui/core'
import { Box } from '@mui/material'
import { getCookie } from './Utils/CookieHelper'

const dark = getCookie('theme_mode')

const DirectChangePassword = () => {
  return (
    <div>
        <Box display='flex' flexDirection="column" justifyContent='center' marginTop='20vh'>
                        <Box display="flex" justifyContent="center" >
                            <Card style={{ width: '500px', backgroundColor: dark ? '#272727' :'#eafbf4', }}>
                                <Box sx={{ m:4}}>
                              <ChangePassword/>
                              </Box>
                            </Card>
                        </Box>
                    </Box >
    </div>
  )
}

export default DirectChangePassword