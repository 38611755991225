import React, { Component } from 'react'
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper';
import { withSnackbar } from 'notistack';
import { getDarkMode, getMode } from '../Helper/CommonThings';
import Board from "react-trello";
import { getLaneWidth, getPriority, screenSizeCheck } from './KanbanHelper';
import CustomCard from './CustomCard';
import CustomAddCard from './CustomAddCard';
import AddCardLink from './AddCardLink';
import { AppBar, Avatar, Box, Button, Chip, CircularProgress, createTheme, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import moment from 'moment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from 'material-table';

const user = JSON.parse(getCookie("bb_metaverse_user"));

const theme = createTheme({
    palette: {
        type: getDarkMode(),
        background: {
            light: {
                paper: '#fff',
                default: '#fafafa',
                primary: '#e6e6e6'
            },
            dark: {
                paper: '#424242',
                default: '#303030',
                primary: "#595959",
                secondary: '#a6a6a6'
            }
        },
        text: {
            light: {
                primary: '#000',
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                800: '#424242',
                900: '#212121',
                A100: '#d5d5d5',
                A200: '#aaaaaa',
                A400: '#303030',
                A700: '#616161',
            },
            dark: {
                primary: '#ffffff',
                50: '#fafafa',
                100: '#f5f5f5',
                200: '#eeeeee',
                300: '#e0e0e0',
                400: '#bdbdbd',
                500: '#9e9e9e',
                600: '#757575',
                700: '#616161',
                800: '#424242',
                900: '#212121',
                A100: '#d5d5d5',
                A200: '#aaaaaa',
                A400: '#303030',
                A700: '#616161',
            }
        }
    }
})


var token = getCookie("bb_metaverse_token");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class VersionPlanner extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            loading: true,
            progress: [],
            completedData: {},
            partialComments: [],
            dialogueData: null,
            archiveTaskDialog: false,
            archievedData: [],
        }
        this.dialogueData = this.dialogueData.bind(this)
        this.userUpdate = this.userUpdate.bind(this)
        this.addCard = this.addCard.bind(this)
    }

    userUpdate(card) {
        var data = this.state.data
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            for (let j = 0; j < laneItem.cards.length; j++) {
                const cardItem = laneItem.cards[j];
                if (cardItem.id === card.id) {
                    laneItem.cards[j] = card
                    break
                }
            }
        }
        this.setState({ data })
    }

    dialogueData(data) {
        this.setState({ openDialog: true })
        Service.get(`/api/work/order/get_workorder_data/?card_id=${data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                this.setState({
                    dialogueData: res.data,
                    dialogueLoading: false,
                    partialComments: comments,
                    fullComments: res.data.comments,
                    originalData: res.data
                })
            })
            .catch(error => {
                this.setState({ openDialog: false, dialogueLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    viewAllComments() {
        let data = this.state.fullComments
        let partialComments = this.state.partialComments
        partialComments = data
        this.setState({ partialComments })
    }

    addComments(card_id) {
        if (this.state.comments) {
            Service.post(`/api/work/order/create_workorder_comments/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    comment: this.state.comments,
                    card_id: card_id,
                    created_by: user.id
                }
            })
                .then(res => {
                    let comments = []
                    if (res.data.comments.length < 5) {
                        comments = res.data.comments
                    } else {
                        for (let i = 0; i < 5; i++) {
                            const element = res.data.comments[i];
                            if (element) {
                                comments.push(element)
                            }
                        }
                    }
                    this.setState({
                        partialComments: comments,
                        comments: '',
                        fullComments: res.data.comments
                    })
                    this.props.enqueueSnackbar('Comments Added successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                });
        } else {
            this.props.enqueueSnackbar('Please Add a Comment', {
                variant: 'warning'
            });
        }
    }

    getWorkOrder() {
        var laneStyle = {
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.dark.primary : theme.palette.background.light.primary,
            color: theme.palette.type === 'dark' ? theme.palette.text.dark.primary : theme.palette.text.light.primary,
            width: "250px"
        }
        Service.get(`/api/work/order/before_completion_based_on_version/?version_id=${this.props.version.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            var progress = []
            laneStyle.width = getLaneWidth(res.data.lanes.length)
            for (let i = 0; i < res.data.lanes.length; i++) {
                const lane = res.data.lanes[i];
                lane.style = laneStyle
                progress.push({ laneID: lane.id, name: lane.title })
            }
            if (this.state.completedData) {
                for (let i = 0; i < res.data.lanes.length; i++) {
                    const element = res.data.lanes[i];
                    if (element.id === this.state.completedData.id) {
                        res.data.lanes[i].cards = this.state.completedData.cards
                    }
                }
            }
            this.setState({
                data: res.data,
                progress: progress,
                loading: false

            }, () => {
               this.getCompletedWorkOrder()
            })

        }).catch(error => {
            console.log(error);
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        })


    }

    getCompletedWorkOrder() {
        Service.get(`/api/work/order/only_completion_based_on_version/?version_id=${this.props.version.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ completedData: res.data })
            var data = this.state.data
            for (let i = 0; i < data.lanes.length; i++) {
                const element = data.lanes[i];
                if (element.id === res.data.id) {
                    data.lanes[i].cards = res.data.cards
                }
            }
            this.setState({ data })

        }).catch(error => {
            if (error && (error.status === 406 || (error.response && error.response.status === 406))) {
                return null
            } else {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            }
        })
    }

    onCardMoveAcrossLanes(oldLane, newLane, cardId) {
        let data = this.state.data
        let card = null
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            if (laneItem.id === oldLane.toString()) {
                for (let j = 0; j < laneItem.cards.length; j++) {
                    const cardItem = laneItem.cards[j];
                    if (cardItem.id === cardId.toString()) {
                        card = laneItem.cards[j]
                        laneItem.cards.splice(j, 1)
                        break
                    }
                }
            }
        }
        for (let i = 0; i < data.lanes.length; i++) {
            const laneItem = data.lanes[i];
            if (laneItem.id === newLane.toString()) {
                card.laneId = newLane.toString()
                laneItem.cards = [...[card], ...laneItem.cards]
                break
            }
        }
        this.setState({ data, loading: true }, () => { this.setState({ loading: false }) })

        //update from api
        Service.get(`/api/work/order/status_change/?id=${cardId}&moved_to=${newLane}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
            })
            .catch(error => {
                this.getWorkOrder()
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    addUserDialogue(value, card_id) {
        this.setState({ addIconDisabled: true })
        Service.get(`/api/work/order/add_user/?user_id=${value.user_id}&card_id=${card_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                var data = this.state.dialogueData
                data['users'] = res.data.users
                data['suggested_users'] = res.data.suggested_users
                this.setState({
                    dialogueData: data,
                    addIconDisabled: false,
                    updated_card: res.data,
                    partialComments: comments,
                    fullComments: res.data.comments,
                })
            })
            .catch(error => {
                this.setState({ addIconDisabled: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    removeUserDialogue(value, card_id) {
        this.setState({ removeIconDisabled: true })
        Service.get(`/api/work/order/remove_user/?user_id=${value.user_id}&card_id=${card_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let comments = []
                if (res.data.comments.length < 5) {
                    comments = res.data.comments
                } else {
                    for (let i = 0; i < 5; i++) {
                        const element = res.data.comments[i];
                        if (element) {
                            comments.push(element)
                        }
                    }
                }
                var data = this.state.dialogueData
                data['users'] = res.data.users
                data['suggested_users'] = res.data.suggested_users
                this.setState({
                    dialogueData: data,
                    removeIconDisabled: false,
                    updated_card: res.data,
                    partialComments: comments,
                    fullComments: res.data.comments,
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                this.setState({ removeIconDisabled: false })
            });
    }

    componentDidMount() {
        this.getWorkOrder()
    }

    editCardDetails(card_id) {
        if (!this.state.dialogueData.title) {
            this.props.enqueueSnackbar('Sub Task Name should not be Empty!', {
                variant: 'warning'
            });
        } else if (this.state.dialogueData.planned_end && this.state.dialogueData.planned_start) {
            if ((moment(this.state.dialogueData.planned_end).format('YYYY-MM-DD')) >= (moment(this.state.dialogueData.planned_start).format('YYYY-MM-DD'))) {
                Service.patch('/api/work/order/workorder_edit_api/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: {
                        id: card_id,
                        description: this.state.dialogueData.description,
                        priority: this.state.dialogueData.priority,
                        planned_start: (moment(this.state.dialogueData.planned_start).format('YYYY-MM-DD')),
                        planned_end: (moment(this.state.dialogueData.planned_end).format('YYYY-MM-DD')),
                        moved_to: this.state.dialogueData.laneTitle,
                        name: this.state.dialogueData.title
                    }
                })
                    .then(res => {
                        let data = this.state.data
                        if (this.state.originalData.laneId === res.data.laneId) {
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === res.data.laneId) {
                                    for (let j = 0; j < laneItem.cards.length; j++) {
                                        const cardItem = laneItem.cards[j];
                                        if (cardItem.id === res.data.id) {
                                            laneItem.cards[j] = res.data
                                            break
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === this.state.originalData.laneId) {
                                    for (let j = 0; j < laneItem.cards.length; j++) {
                                        const cardItem = laneItem.cards[j];
                                        if (cardItem.id === this.state.originalData.id) {
                                            laneItem.cards.splice(j, 1)
                                            break
                                        }
                                    }
                                }
                            }
                            for (let i = 0; i < data.lanes.length; i++) {
                                const laneItem = data.lanes[i];
                                if (laneItem.id === res.data.laneId) {
                                    // laneItem.cards.push(res.data)
                                    laneItem.cards = [...[res.data], ...laneItem.cards]
                                    break
                                }
                            }
                        }

                        this.setState({
                            data: data, openDialog: false, dialogueData: null, originalData: null, partialComments: [], fullComments: [], isLoading: true
                        }, () => { this.setState({ isLoading: false }) });
                        this.props.enqueueSnackbar('Data Saved Successfully!', {
                            variant: 'success'
                        });
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                    });
            } else {
                this.props.enqueueSnackbar('End dates are not lesser than Start dates!', {
                    variant: 'warning'
                });
            }
        } else {
            this.props.enqueueSnackbar('Dates are Required!', {
                variant: 'warning'
            });
        }
    }

    handleTextChange(e) {
        if (e.target.id === 'description') {
            let data = this.state.dialogueData
            data['description'] = e.target.value
            this.setState({
                dialogueData: data,
            })
        } else if (e.target.id === 'subtaskname') {
            let data = this.state.dialogueData
            data['title'] = e.target.value
            this.setState({
                dialogueData: data,
            })
        } else if (e.target.id === 'comments') {
            this.setState({
                comments: e.target.value,
            })
        }
    }

    addCard(cardItems, laneId) {
        var data = this.state.data
        for (let i = 0; i < data.lanes.length; i++) {
            const element = data.lanes[i];
            if (element.id === laneId) {
                // element.cards.push(cardItems)
                element.cards = [...[cardItems], ...element.cards]
            }
        }
        this.setState({ data })
    }

    getArchivedTasks() {
        this.setState({
            archiveTaskDialog: true
        })
        Service.get(`/api/work/order/get_archived_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    archievedData: res.data
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                this.setState({ removeIconDisabled: false })
            });
    }

    unArchive(data) {
        Service.get(`/api/work/order/un_archived/?card_id=${data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.getArchivedTasks()
                this.getWorkOrder()
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    render() {

        // const classes = this.props.classes

        const components = {
            Card: (props) => <CustomCard
                props={props}
                data={this.state.data}
                version={this.props.version}
                dialogueData={this.dialogueData}
                userUpdate={this.userUpdate}
            />,
            NewCardForm: (props) => <CustomAddCard
                props={props}
                data={this.state.data}
                version={this.props.version}
                addCard={this.addCard}
            />,
            AddCardLink: (props) => <AddCardLink props={props} data={this.state.data}
                version={this.props.version}
            />
        }

        const handleDialogueClose = () => {
            this.setState({ openDialog: false })
        }

        const handleStartChange = (date) => {
            let data = this.state.dialogueData
            data['planned_start'] = date
            this.setState({
                dialogueData: data,
            })
        }

        const handleEndChange = (date) => {
            let data = this.state.dialogueData
            data['planned_end'] = date
            this.setState({
                dialogueData: data,
            })
        }

        const handlePriorityChange = (event) => {
            let data = this.state.dialogueData
            data['priority'] = event.target.value
            this.setState({ dialogueData: data, });
        }

        const handleProgressChange = (event) => {
            let data = this.state.dialogueData
            data['laneTitle'] = event.target.value
            this.setState({ dialogueData: data, });
        }

        return (
            <Box marginTop={10}>
                {this.state.loading ?
                    <Box display="flex" justifyContent="center" alignItems='center'><CircularProgress /></Box> :
                    <div>
                        <Box display="flex" justifyContent={"flex-end"} alignItems={"center"}>
                            <Link onClick={() => this.getArchivedTasks(this.state.dialogueData ? this.state.dialogueData : null)}>Archived Tasks</Link>
                        </Box>
                        <Board
                            data={this.state.data}
                            cardDragClass="draggingCard"
                            draggable={false}
                            customCardLayout
                            components={components}
                            editable
                            style={{ backgroundColor: theme.palette.type === 'dark' ? 'transparent' : theme.palette.background.light.default }}
                            onCardMoveAcrossLanes={(oldLane, newLane, cardId) => { console.log(newLane); this.onCardMoveAcrossLanes(oldLane, newLane, cardId) }}
                            onCardAdd={(card, laneid) => this.addCard(card, laneid)}
                        >
                        </Board>
                        <Dialog disablePortal fullWidth={true} maxWidth={'md'} open={this.state.openDialog} onClose={handleDialogueClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition} sx={{ zIndex: 999 }}>
                            {this.state.dialogueLoading ?
                                <Box display='flex' flexDirection={'row'} justifyContent='center'><CircularProgress /></Box>
                                :
                                // <EditCardDetails
                                //     progress={this.state.progress}
                                //     cardDetails={this.state.dialogueData ? this.state.dialogueData : null}
                                //     closeDialog={handleDialogueClose}
                                //     partialComments={this.state.partialComments}
                                //     fullComments={this.state.fullComments}
                                //     originalData={this.state.originalData}
                                // />
                                <>
                                    <AppBar position="static" color="primary">
                                        <Toolbar>
                                            <Box display='flex' flexDirection={'row'} width={'100%'}>
                                                <Box flexGrow={1}>
                                                    <Typography variant='h6'>{this.state.dialogueData ? this.state.dialogueData.title : null}</Typography>
                                                </Box>
                                                <Box>
                                                    <Button color='secondary' onClick={() => this.editCardDetails(this.state.dialogueData ? this.state.dialogueData.id : null)}>save</Button>
                                                </Box>
                                                <Box>
                                                    <Button color='secondary' onClick={handleDialogueClose}>
                                                        close
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Toolbar>
                                    </AppBar>
                                    <DialogContent>
                                        <Box display='flex' flexDirection='column' m={1}>
                                            <Box display='flex' flexDirection='row' style={{ width: '100%' }} justifyContent='center'>
                                                <TextField
                                                    id="subtaskname"
                                                    label="Sub Task Name"
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => this.handleTextChange(e)}
                                                    value={this.state.dialogueData ? this.state.dialogueData.title : ''}
                                                />
                                            </Box>
                                            <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                            <Box display='flex' flexDirection='row' >
                                                <Box>
                                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                                        {(popupState) => (
                                                            <div>
                                                                <IconButton style={{ marginLeft: '5px' }} {...bindTrigger(popupState)}>
                                                                    <GroupAddIcon fontSize='small' />
                                                                </IconButton>
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}
                                                                // onClose={() => this.updatedResponse(popupState)}
                                                                >
                                                                    <Box m={2} width={300} maxHeight={screenSizeCheck() ? 500 : 600}>
                                                                        <Box display={'flex'} flexDirection={'column'}>
                                                                            {/* <Box width={'100%'}><TextField id="standard-basic" label="Type a name" style={{ width: '100%' }} /></Box> */}
                                                                            <Box >
                                                                                <Box fontWeight={'bold'}>Assigned</Box>
                                                                                <Box mt={1}>
                                                                                    {this.state.dialogueData ?
                                                                                        this.state.dialogueData.users.length !== 0 ?
                                                                                            <List dense>
                                                                                                {this.state.dialogueData.users.map((value, i) =>
                                                                                                    <ListItem key={i}>
                                                                                                        <ListItemAvatar>
                                                                                                            <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                                                        </ListItemAvatar>
                                                                                                        <ListItemText
                                                                                                            primary={value.last_name + ", " + value.first_name}
                                                                                                        />
                                                                                                        <ListItemSecondaryAction>
                                                                                                            <IconButton edge="end" aria-label="delete" onClick={() => this.removeUserDialogue(value, this.state.dialogueData ? this.state.dialogueData.id : null)} disabled={this.state.removeIconDisabled}>
                                                                                                                <CloseIcon fontSize='small' />
                                                                                                            </IconButton>
                                                                                                        </ListItemSecondaryAction>
                                                                                                    </ListItem>
                                                                                                )}
                                                                                            </List> :
                                                                                            'There is no assigned users' : null
                                                                                    }
                                                                                </Box>
                                                                            </Box>

                                                                            <Box mt={1}>
                                                                                <Box fontWeight={'bold'}>Suggestions</Box>
                                                                                <Box mt={1}>
                                                                                    {(this.state.dialogueData) && (this.state.dialogueData.suggested_users.length !== 0) ?
                                                                                        <List dense>
                                                                                            {this.state.dialogueData.suggested_users.map((value, i) =>
                                                                                                <ListItem key={i}>
                                                                                                    <ListItemAvatar>
                                                                                                        <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                                                    </ListItemAvatar>
                                                                                                    <ListItemText
                                                                                                        primary={value.last_name + ", " + value.first_name}
                                                                                                    />
                                                                                                    <ListItemSecondaryAction>
                                                                                                        <IconButton edge="end" aria-label="delete" onClick={() => this.addUserDialogue(value, this.state.dialogueData ? this.state.dialogueData.id : null)} disabled={this.state.addIconDisabled}>
                                                                                                            <AddIcon fontSize='small' />
                                                                                                        </IconButton>
                                                                                                    </ListItemSecondaryAction>
                                                                                                </ListItem>
                                                                                            )}
                                                                                        </List>
                                                                                        : 'Users assigned to the tasks are displayed here'}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Popover>
                                                            </div>
                                                        )}
                                                    </PopupState>
                                                </Box>
                                                <Box
                                                    // className={classes.root}
                                                    display="flex" flexDirection={'row'}
                                                >
                                                    {this.state.dialogueData ? this.state.dialogueData.users.map((ele, i) => {
                                                        return (<li key={i}>
                                                            <Chip
                                                                avatar={<Avatar>{ele.first_name.charAt(0) + '' + ele.last_name.charAt(0)}</Avatar>}
                                                                label={ele.first_name}
                                                                color={"default"}
                                                                // className={classes.chip}
                                                                onDelete={() => this.removeUserDialogue(ele, this.state.dialogueData ? this.state.dialogueData.id : null)}
                                                            />
                                                        </li>
                                                        )
                                                    }) : null}
                                                </Box>
                                            </Box>
                                            <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                            <Box display='flex' flexDirection='row' mt={1} justifyContent='left'>
                                                <Box display='flex' flexDirection='column' marginLeft={'5px'} marginRight={'30px'}>
                                                    <Box>
                                                        <FormControl variant="outlined" style={{ width: 300 }}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Progress</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="priority"
                                                                value={this.state.dialogueData ? this.state.dialogueData.laneTitle : ''}
                                                                onChange={handleProgressChange}
                                                                label="Priority">
                                                                {this.state.progress.map((value, i) =>
                                                                    <MenuItem key={i} value={value.name}>{value.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                                <Box display='flex' flexDirection='column' marginLeft={'5px'} >
                                                    <Box>
                                                        <FormControl variant="outlined" style={{ width: 300 }}>
                                                            <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="priority"
                                                                value={this.state.dialogueData ? this.state.dialogueData.priority : ''}
                                                                onChange={handlePriorityChange}
                                                                label="Priority">
                                                                <MenuItem value={null}>None</MenuItem>
                                                                {getPriority().map((value, i) =>
                                                                    <MenuItem key={i} value={value.key}>{value.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box display='flex' flexDirection='row' mt={1} justifyContent='left'>
                                                <Box display='flex' flexDirection='column' marginLeft={'5px'} marginRight={'30px'}>
                                                    <Box>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                variant='outlined'
                                                                margin="normal"
                                                                format="dd/MM/yyyy"
                                                                value={this.state.dialogueData ? this.state.dialogueData.planned_start : null}
                                                                onChange={handleStartChange}
                                                                clearable
                                                                fullWidth
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'Planned start',
                                                                }}
                                                                label='Planned Start'
                                                                style={{ width: 300 }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                </Box>
                                                <Box display='flex' flexDirection='column' marginLeft={'5px'}>
                                                    <Box>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                variant='outlined'
                                                                margin="normal"
                                                                format="dd/MM/yyyy"
                                                                value={this.state.dialogueData ? this.state.dialogueData.planned_end : null}
                                                                onChange={handleEndChange}
                                                                clearable
                                                                fullWidth
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'Planned end',
                                                                }}
                                                                label='Planned End'
                                                                style={{ width: 300 }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                            <Box display='flex' flexDirection='row' mt={1} style={{ width: '100%' }} justifyContent='space-around'>
                                                <Box display='flex' flexDirection='column' style={{ width: '100%' }} >
                                                    <Box style={{ width: '100%' }} justifyContent='center'>
                                                        <TextField
                                                            id="description"
                                                            label="Description"
                                                            multiline
                                                            rows={4}
                                                            variant="outlined"
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => this.handleTextChange(e)}
                                                            value={this.state.dialogueData ? this.state.dialogueData.description !== null ? this.state.dialogueData.description : '' : ''}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                            <Box display='flex' flexDirection='row' style={{ width: '100%' }}>
                                                <Box display='flex' flexDirection='column' style={{ width: '100%' }} >
                                                    <Box fontWeight={'bold'}>
                                                        Comments
                                                    </Box>
                                                    <Box style={{ width: '100%' }} justifyContent='center' mt={1}>
                                                        <TextField
                                                            id="comments"
                                                            label="Type a comment..."
                                                            multiline
                                                            rows={2}
                                                            variant="outlined"
                                                            style={{ width: '100%' }}
                                                            value={this.state.comments}
                                                            onChange={(e) => this.handleTextChange(e)}
                                                        />
                                                    </Box>
                                                    <Box display='flex' width={'100%'} flexDirection="row-reverse" marginRight={'10px'} marginTop={'5px'}>
                                                        <Button color={getMode() ? 'secondary' : 'primary'} onClick={() => this.addComments(this.state.dialogueData ? this.state.dialogueData.id : null)}>Add Comments</Button>
                                                    </Box>
                                                    <Box style={{ width: '100%' }}>
                                                        {this.state.partialComments.length !== 0 ? this.state.partialComments.map((value, i) =>
                                                            <Box key={i} marginTop='10px' marginBottom={'5px'} display={'flex'} flexDirection='column'>
                                                                <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                                                                    <Box display={'flex'} flexDirection='row' flexGrow={1} alignItems={'center'}>
                                                                        <Box>
                                                                            <Avatar sx={{ bgcolor: '#99CFBB' }}
                                                                            // className={classes.small}
                                                                            ><Box fontWeight={'bold'} fontSize={'9px'} >{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                        </Box>
                                                                        <Box marginLeft="10px" fontWeight={500}>{value.first_name + ", " + value.last_name}</Box>
                                                                    </Box>
                                                                    <Box>{moment(value.date_created).format(' DD MMM YYYY, h:mm')}</Box>
                                                                </Box>
                                                                <Box marginLeft='37px'>
                                                                    {value.comment}
                                                                </Box>
                                                            </Box>
                                                        ) : null
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {
                                                this.state.partialComments.length !== 0 && this.state.partialComments.length >= 5 ?
                                                    <>
                                                        <Divider variant="middle" style={{ marginTop: '15px', marginBottom: '15px' }} />
                                                        <Box display='flex' flexDirection='row' style={{ width: '100%' }} justifyContent='center'>
                                                            <Button color={getMode() ? 'secondary' : 'primary'} onClick={() => this.viewAllComments()}>View All Comments</Button>
                                                        </Box>
                                                    </>
                                                    : null
                                            }

                                        </Box>
                                    </DialogContent>
                                </>
                            }
                        </Dialog>
                        <Dialog disablePortal maxWidth="lg" open={this.state.archiveTaskDialog} onClose={() => this.setState({ archiveTaskDialog: false })}>
                            <DialogTitle>Archived Task</DialogTitle>
                            <DialogContent>
                                <MaterialTable
                                    title=""
                                    columns={[
                                        { title: 'Task', field: 'name' },
                                        { title: 'Planned Start', field: 'planned_start' },
                                        { title: 'Planned End', field: 'planned_end' },
                                        {
                                            title: 'Priority', field: 'priority',
                                            lookup: this.state.priorityLookup
                                        },
                                        {
                                            title: 'Status', field: 'workorder_status_id',
                                            lookup: this.state.lanesLookup
                                        },
                                    ]}
                                    data={this.state.archievedData}
                                    actions={[
                                        rowData => ({
                                            icon: 'unarchive',
                                            tooltip: 'UnArchive',
                                            onClick: (event, rowData) => { this.unArchive(rowData) }
                                        }),
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit',
                                            onClick: (event, rowData) => { this.dialogueData(rowData) }
                                        }),
                                    ]}
                                    options={{
                                        padding: "dense",
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50],
                                        filtering: true,
                                        actionsColumnIndex: -1
                                    }}
                                />
                            </DialogContent>
                        </Dialog>

                    </div>
                }
            </Box>
        )
    }
}

export default withSnackbar(VersionPlanner)
