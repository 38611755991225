import React, { useEffect } from 'react'
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar, withSnackbar } from "notistack";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProjectAssets from './ProjectAssets';
import {  Accordion, AccordionSummary, Box, Typography } from '@material-ui/core';
import { AccordionDetails} from '@mui/material';

var token = getCookie("bb_metaverse_token");

const Assets = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = React.useState([])

  const getType = () => {
    Service.get(`/api/asset/types/`, {
      headers: {
        Authorization: "Token " + token,
      },
    }).then(res => {
      setType(res.data)

    }).catch(error => {
      console.log(error);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      })
    })
  }

  useEffect(() => {
    getType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <Box mt={10}>
        {type.map((data, index) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{data.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProjectAssets row={props.row} />
              </AccordionDetails>
            </Accordion>)
        })}
      </Box>
    </div>
  )
}

export default withSnackbar(Assets)