import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemButton} from '@mui/material'
import React, { useState } from 'react'
import { blobDomain } from '../Helper/BlobHelper'
import { blobSAS } from '../Helper/BlobHelper'
import { ListItemText } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import JsFileDownloader from 'js-file-downloader';

const OpenAssetList = (props) => {

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)



  const download = (url, projectName, assetname, asset_source) => {

    // alert(asset_source)
    setLoading(true)

    let source = asset_source.split("/")
    let fileExtension = source[source.length - 1]
    let extension = fileExtension.split(".")
    let fileTypeExtension = extension[extension.length - 1]

    let fileName = projectName + assetname + "." + fileTypeExtension


    new JsFileDownloader({ url: url, filename: fileName, timeout: 0 })
      .then(function () {
        // Called when download ended
        setLoading(false)
        enqueueSnackbar('File Downloaded Successfully ', {
          variant: 'success'
        });
      })
      .catch(function (error) {
        // Called when an error occurred
        setLoading(false)
        console.log(error)
        enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      });
  }

  return (
    <div>
      <List dense={true}>
        {props.assets.map((data, index) => {
          return (
            // <a style={{textDecoration:'none'}} href={blobDomain() + data.asset_source + "?" + blobSAS()}  title="Downloadable Assets" rel="noopener noreferrer">
            <ListItemButton onClick={() => download(blobDomain() + data.asset_source + "?" + blobSAS(), props.projectName, data.name, data.asset_source)}>
              <ListItem>
                <ListItemText
                  primary={data.name}
                  secondary={data.description ? data.description : null}
                />
              </ListItem>
            </ListItemButton>
            // </a>
          )
        })}
      </List>
      <Dialog maxWidth="xs" fullWidth={true} open={loading}  >
         <DialogTitle marginTop="2px" spacing={2} display="flex" justifyContent="center" alignItems="center">Downloading...</DialogTitle> 
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" margin={2}>
            <CircularProgress />
            {/* <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={this.state.progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="primary">{`${Math.round(
                this.state.progress,
              )}%`}</Typography>
            </Box> */}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default OpenAssetList