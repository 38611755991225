import { Box, DialogActions, DialogTitle,  List, ListItem, ListItemButton,  Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { blobDomain } from '../Helper/BlobHelper'
import { blobSAS } from '../Helper/BlobHelper'
import CallSplitIcon from '@mui/icons-material/CallSplit';
import VideoVersionList from './VideoVersionList'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, ListItemText } from '@material-ui/core'


const VideoList = (props) => {

    const [url, setUrl] = useState(null);
    const [videoDialog, setVideoDialog] = useState(false)
    const [version, setVersion] = useState(null)
    const [versionListsDialog, setVersionListsDialog] = useState(false)

    const handleVideoDialog = () => {
        setVideoDialog(false)
    }

    const handleVersionListDialog = () => {
        setVersionListsDialog(false)
    }

    return (
        <div>
            <div>
                <List dense={true}>
                    {props.videos.map((data, index) => {
                        return (
                            <ListItemButton onClick={() => { setVideoDialog(true); setUrl(data.asset_source);}}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => { e.stopPropagation(); setVersion(data); setVersionListsDialog(true) }}>
                                            <CallSplitIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={data.name}
                                        secondary={data.description ? data.description: null}
                                    />
                                </ListItem>
                            </ListItemButton>
                        )
                    })}
                </List>
            </div>
            <Dialog fullWidth={true} maxWidth="md" open={videoDialog} onClose={handleVideoDialog}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ m: 2 }}>
                        <ReactPlayer
                            // width="100%" 
                            url={blobDomain() + url + "?" + blobSAS()}
                            controls={true} pip={true} stopOnUnmount={false}
                            width="500" height="500"
                            // light={value.thumbnail}
                            playing
                            playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        onContextMenu: e => e.preventDefault()
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="md" open={versionListsDialog} onClose={handleVersionListDialog}>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Box sx={{display:'flex'}}>
                    <Box sx={{flexGrow:1}}><Typography variant="h6">Versions</Typography></Box>
                    <Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleVersionListDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                    </Box>
                    </Box>
                </DialogTitle>
                <VideoVersionList data={version} />
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VideoList