import { Box,  IconButton,  List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { blobDomain } from '../Helper/BlobHelper'
import { blobSAS } from '../Helper/BlobHelper'
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper'
import { useSnackbar } from "notistack";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Dialog } from '@material-ui/core'


var token = getCookie("bb_metaverse_token");


const VideoVersionList = (props) => {

    const [url, setUrl] = useState(null);
    const [videoDialog, setVideoDialog] = useState(false)
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar();


    const handleVideoDialog = () => {
        setVideoDialog(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getData() {
        Service.get(`/api/asset/version/get_version_by_project/?project_asset=${props.data.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)

        }).catch(error => {
            console.log(error);
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        })
    }

    return (
        <div>
            <div>
                <List dense={true}>
                    {data.map((data, index) => {
                        return (
                            <ListItemButton onClick={() => { setVideoDialog(true); setUrl(data.asset_source); }}>
                                <ListItem>
                                    <ListItemText
                                        primary={data.version}
                                        secondary={data.change_log ? data.change_log : null}
                                    />
                                </ListItem>
                            </ListItemButton>
                        )
                    })}
                </List>
            </div>
            <Dialog fullWidth={true} maxWidth="md" open={videoDialog} onClose={handleVideoDialog}>
                <Box sx={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                <Box sx={{m:2}}>
                <ReactPlayer 
                // width="100%" 
                url={blobDomain() + url + "?" + blobSAS()} 
                controls={true} pip={true} stopOnUnmount={false} 
                width="500" height="500"
                // light={value.thumbnail}
                playing
                playIcon={<IconButton><PlayArrowIcon color="primary" sx={{ fontSize: 120 }} /></IconButton>}
                config={{
                        file: {
                            attributes: {
                                         controlsList: 'nodownload',
                                         onContextMenu: e => e.preventDefault()
                                        }}
                                    }}
                />
                </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default VideoVersionList