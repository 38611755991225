import MaterialTable from 'material-table'
import React, { useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import Service from '../Networking/NetworkingUtils';
import { useSnackbar, withSnackbar } from 'notistack';
import { getCookie } from '../Utils/CookieHelper';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import ProjectAccessList from './ProjectAccessList';
import { Box,  Dialog,  TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const token = getCookie("bb_metaverse_token");
var userCookie = JSON.parse(getCookie('bb_metaverse_user'))
// const dark = getCookie('theme_mode')


const UserAccess = (props) => {

    const [addDialog, setAddDialog] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [user, setUser] = React.useState('')
    const [loading, setLoading] = React.useState(true)
    const [tableData, setTableData] = React.useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = React.useState(false)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [addUserDisabled, setAddUserDisabled] = React.useState(false)
    const [left, setLeft] = React.useState([])
    const [right, setRight] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [lookup, setLookup] = React.useState({})
    const [data, setData] = React.useState([])
    const initialRender = React.useRef(true);

    function handleAddUserClose() {
        setAddDialog(false)
    }

    function addUser() {

        if (!user) {
            enqueueSnackbar('Please Select User', {
                variant: 'warning'
            });
            return null
        }

        if (!checked) {
            if ((!startDate && !endDate)) {
                enqueueSnackbar('you have to select either unlimited or start and end date', {
                    variant: 'warning'
                });
            } else if (!startDate || !endDate) {
                enqueueSnackbar('Please choose both start date and end date', {
                    variant: 'warning'
                });
            }
            else {
                let data = {
                    "start_date": dayjs(startDate).format('YYYY-MM-DD'),
                    "end_date": dayjs(endDate).format('YYYY-MM-DD'),
                    "is_unlimited": false,
                    "user": user.id,
                    "project": props.project.id,
                    "created_by": userCookie.id
                }
                postUserAccess(data)
            }
        } else {

            let data = {
                "start_date": null,
                "end_date": null,
                "is_unlimited": true,
                "user": user.id,
                "project": props.project.id,
                "created_by": userCookie.id
            }
            postUserAccess(data)
        }

        function postUserAccess(data) {
            setAddUserDisabled(true)
            Service.post(`/api/projects/access/create_project_level_access/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    enqueueSnackbar('User Added Successfully', {
                        variant: 'success'
                    });
                    setAddDialog(false)
                    setChecked(false)
                    setUser(null)
                    setStartDate(null)
                    setEndDate(null)
                    setAddUserDisabled(false)
                    getTableData()
                })
                .catch(error => {
                    console.log(error)
                    setAddUserDisabled(false)
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        }
    }

    function getUsers(data) {
        Service.get('/api/users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let existUserIDs = []
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    existUserIDs.push(element.user)
                }
                let users = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    if (!existUserIDs.includes(element.id)) {
                        users.push(element)
                    }
                }
                setUsers(users)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function getTableData() {
        Service.get(`/api/projects/level/access/get_project_level_useraccess/?project=${props.project.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                setTableData(res.data)
                setLoading(false)
                getUsers(res.data)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function openDialog() {
        setAddDialog(true)
    }

    function closeUserDialog() {
        setAddDialog(false)
        setChecked(false)
        setUser(null)
        setStartDate(null)
        setEndDate(null)
    }

    function editUserAccess(rowData) {
        if (rowData.is_unlimited === "true") {
            rowData.start_date = null
            rowData.end_date = null
            editFunc(rowData)
        } else if (rowData.is_unlimited === "false") {
            if (rowData.start_date === null || rowData.end_date === null) {
                enqueueSnackbar('Start Date and End Date cannot be empty if Unlimited is false', {
                    variant: 'warning'
                });
            } else if (moment(rowData.start_date) >= moment(rowData.end_date)) {
                enqueueSnackbar('Start Date should be lower than end Date', {
                    variant: 'warning'
                });
            }
            else {
                rowData.start_date = rowData.start_date ? dayjs(rowData.start_date).format('YYYY-MM-DD') : null
                rowData.end_date = rowData.end_date ? dayjs(rowData.end_date).format('YYYY-MM-DD') : null
                editFunc(rowData)
            }
        }
        else {
            rowData.start_date = rowData.start_date ? dayjs(rowData.start_date).format('YYYY-MM-DD') : null
            rowData.end_date = rowData.end_date ? dayjs(rowData.end_date).format('YYYY-MM-DD') : null
            editFunc(rowData)
        }
    }

    function editFunc(rowData) {
        Service.put(`/api/projects/access/` + rowData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: rowData
        })
            .then(res => {
                enqueueSnackbar('Access Updated Succesfully', {
                    variant: 'success'
                });
                setChecked(false)
                setStartDate(null)
                setEndDate(null)
                getTableData()
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function deleteUserAccess(rowData) {
        Service.delete(`/api/projects/access/` + rowData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                enqueueSnackbar('Access Deleted Succesfully', {
                    variant: 'success'
                });
                setChecked(false)
                setStartDate(null)
                setEndDate(null)
                getTableData()
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    function transferList(rowData) {
        if (left.length === 0 && right.length === 0) {
            if (rowData) {
                const element = rowData.access_data
                for (const key in element) {
                    if (element[key] === true) {
                        right.push(key)
                        setRight(right)
                    } else {
                        left.push(key)
                        setLeft(left)
                    }
                }
            }
        }
    }

    function handleAccess() {
        transferList(data)
    }

    function getAcess() {
        Service.get(`/api/projects/access/list/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let obj = {}
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    element.isChecked = false
                    obj[element.access_key] = element.name
                }
                setLookup(obj)
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    useEffect(() => {
        getTableData()
        getAcess()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (!open) {
                setLoading(true)
                getTableData()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])


    return (
        <div style={{ width: '100%' }}>
            <MaterialTable
                title={props.project.name}
                isLoading={loading}
                data={tableData}
                columns={[
                    { title: 'Name', field: 'user_details', editable: 'never', render: rowData => rowData.user_details.last_name + ", " + rowData.user_details.first_name },
                    { title: 'Stakeholder', field: 'stakeholder', type: 'boolean'},
                    { title: 'Unlimited', field: 'is_unlimited', lookup: { true: 'True', false: 'False' } },
                    { title: 'Start Date', field: 'start_date', type: 'date', validate: rowData => moment(rowData.start_date) >= moment(rowData.end_date) ? 'Start Date should be lower than End Date' : '' },
                    { title: 'End Date', field: 'end_date', type: 'date', validate: rowData => moment(rowData.start_date) >= moment(rowData.end_date) ? 'End Date should be greater than Start Date' : '' }
                ]}
                options={{
                    paging: false,
                    headerStyle: {
                        fontWeight: 'bold',
                    },
                    actionsColumnIndex: -1,
                    filtering: true,
                    detailPanelColumnStyle: { display: 'none' }
                }}
                actions={([
                    {
                        icon: () => <AddIcon />,
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => openDialog()
                    }
                ])}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...tableData];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                editUserAccess(newData)
                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...tableData];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                resolve()
                                deleteUserAccess(oldData)
                            }, 1000)
                        }),
                }}
                detailPanel={[
                    {
                        icon: () => { },
                        render: rowData => {
                            transferList(rowData)
                            return (
                                <div>
                                    <Box sx={{ m: 3 }}>
                                        <ProjectAccessList
                                            left={left}
                                            right={right}
                                            access={rowData}
                                            open={open}
                                            accessCallback={handleAccess}
                                            lookup={lookup}
                                        />
                                    </Box>
                                </div>
                            )
                        },
                    },
                ]}
                onRowClick={(event, rowData, toggleDetailPanel) => {
                    setLeft([])
                    setRight([])
                    setOpen(!open)
                    setData(rowData)
                    toggleDetailPanel()
                }}
                style={{ width: '100%' }}
            />
            <Dialog maxWidth="xs" fullWidth={true} open={addDialog} onClose={handleAddUserClose} disableEnforceFocus>
                <DialogTitle marginTop="2px" spacing={2}>Add User</DialogTitle>
                <DialogContent>
                    <Box mt={1} style={{ display: 'flex', flexDirection: "column" }}>
                        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                            <Autocomplete
                                disablePortal
                                id="users"
                                value={user}
                                options={users}
                                getOptionLabel={(option) => option ? option['last_name'] + ", " + option['first_name'] : ''}
                                renderInput={(params) => <TextField variant='outlined' {...params} label="Users" />}
                                onChange={(event, values) =>
                                    setUser(values)
                                }
                                fullWidth
                            />
                        </Box>

                        <Box sx={{ mt: 2, display: 'flex', flexDirection: "column" }}>
                            <Box>
                                <FormControlLabel
                                    label="Unlimited"
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={() => setChecked(!checked)}
                                        />
                                    }
                                />
                            </Box>
                            <Divider orientation="horizontal" flexItem >
                                OR
                            </Divider>
                            <Box mt={1}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            inputVariant="outlined"
                                            label="Start Date"
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            inputVariant="outlined"
                                            label="End Date"
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="text" onClick={() => closeUserDialog()}>Cancel</Button>
                    <Button color="primary" variant="text" onClick={(e) => addUser()} disabled={addUserDisabled}>Add</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default withSnackbar(UserAccess)