import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar, withSnackbar } from 'notistack';
import ModelVersion from './ModelVersion';
import { Box } from '@material-ui/core';

var token = getCookie("bb_metaverse_token");
const user = JSON.parse(getCookie("bb_metaverse_user"));

const ModelLibrary = (props) => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();

    const getData = () => {
        setIsLoading(true)
        Service.get(`/api/modular/object/?modular_category=${props.rowData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const postData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        data.modular_category = props.rowData.id
        Service.post(`/api/modular/object/?modular_category=${props.rowData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        }).then(res => {
            getData()
            enqueueSnackbar('Model added Successfully!', {
                variant: 'success'
            })
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    const editData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        Service.put(`/api/modular/object/` + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                enqueueSnackbar('Model edited Successfully', {
                    variant: 'success'
                });
                getData()
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }



    return (
        <div>
            <MaterialTable
                title="Model Library"
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Description', field: 'description' },
                    { title: 'Date Created', field: 'date_created', type: 'date' },
                ]}
                isLoading={isLoading}
                data={data}
                detailPanel={rowData =>
                    <Box margin={2}>
                        <ModelVersion model={rowData} />
                    </Box>
                }
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            postData(newData)
                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                                editData(newData)
                                resolve();
                        }),
                    // onRowDelete: oldData =>
                    //   new Promise((resolve, reject) => {
                    //     setTimeout(() => {
                    //       deleteData(oldData)
                    //       const dataDelete = [...data];
                    //       const index = oldData.tableData.id;
                    //       dataDelete.splice(index, 1);
                    //       setData([...dataDelete]);
                    //       resolve()
                    //     }, 1000)
                    //   }),
                }}

                options={{
                    padding: "dense",
                    paging: false,
                    filtering: true,
                    actionsColumnIndex: -1
                }}
            />
        </div>
    )
}

export default withSnackbar(ModelLibrary)