import React, { Component } from 'react'
import clsx from 'clsx';
import { withStyles, Tooltip, IconButton, Popover, Box } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { deepOrange } from '@material-ui/core/colors';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import { getCookie, setCookie } from '../Utils/CookieHelper';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Avatar, DialogContent, DialogTitle, Typography } from '@mui/material';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ProjectManagement from '../ProjectManagement/ProjectManagement';
import Service from '../Networking/NetworkingUtils';
import ChangePassword from './ChangePassword';
import Dialog from '@material-ui/core/Dialog';
import AssignmentIcon from '@mui/icons-material/Assignment';
import User from '../Users/User';
import MyProjectList from '../ProjectList/MyProjectList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ModelStore from '../ModelStore/ModelStore';
import OpenProjectStore from '../ProjectStore/OpenProjectStore';
import ModelCategory from '../ModelCategory/ModelCategory';
import ContactsIcon from '@mui/icons-material/Contacts';
import CloseIcon from '@mui/icons-material/Close';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import AnimationType from '../AnimationType/AnimationType';

const drawerWidth = 240;
var token = getCookie("bb_metaverse_token");
var access = JSON.parse(getCookie("bb_metaverse_access"));

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        // padding: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    tutorial: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
    },
    active: {
        backgroundColor: "red"
    }
});

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8",
            color: '#000'
        },
        "&:hover": {
            color: getDarkMode() === "dark" ? '#fff' : '#000'
        }
    }
})(ListItem);



class Home extends Component {

    constructor(props) {
        super()
        this.feedback = React.createRef();
        this.state = {
            openDrawer: false,
            viewClass: '',
            open: false,
            formValue: false,
            userData: {},
            isLoading: true,
            menus: [],
            passwordDialog: false,
            aboutorContactDialog: false,
            isAbout: false,
        }
    }

    componentDidMount() {
        if (access) {
            access = JSON.parse(getCookie("bb_metaverse_access"));
        }

        token = getCookie("bb_metaverse_token");

        if (!getCookie("bb_metaverse_token")) {
            window.location.href = '/'
        } else {
            if (!getCookie("bb_metaverse_user")) {
                Service.get(`/rest-auth/user/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        setCookie('bb_metaverse_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        this.props.enqueueSnackbar('Welcome back ' + res.data.first_name + '!', {
                            variant: 'info'
                        });
                        this.createMenu(res.data);
                        this.setState({
                            isLoading: false,
                            userData: res.data,
                        });

                    })
                    .catch(error => {
                        console.log(error)
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                    });

                this.getAccess();
            } else {
                const user = JSON.parse(getCookie("bb_metaverse_user"));

                this.props.enqueueSnackbar('Welcome back ' + user.first_name + '!', {
                    variant: 'info'
                });
                this.createMenu(user);
                this.setState({
                    isLoading: false,
                    userData: user,
                });
            }
        }
    }

    getAccess() {
        Service.get(`/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_metaverse_token"),
            },
        })
            .then(res => {
                if (res.data && res.data[0]) {
                    if (access) {
                        if (access.last_updated !== res.data[0].last_updated) {
                            setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                            // accessReloadOrRefresh();
                            window.location.reload();
                        }
                    } else {
                        setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        // accessReloadOrRefresh();
                        window.location.reload();
                    }

                } else {
                    setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    // alert("This user doesn't have basic access")
                }

            })
            .catch(error => {
                setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            });
    }

    openContactorAbout(value) {
        this.setState({
            aboutorContactDialog: true,
            isAbout: value
        })
    }

    createMenu(userData) {
        var accessBuild = [];
        var defaultView = '';
        var hashDone = false;

        var viewHash = window.location.hash.split('?')[0].replace('#', '').replace('%20', ' ').replace('%20', ' ');

        if (viewHash === "My Projects") {
            hashDone = true;
            this.setState({
                viewClass: viewHash,
            })

        }
        accessBuild.push("My Projects")
        defaultView = 'My Projects'

        accessBuild.push('Project Store')

        if (access && access.access) {
            if ((access.access.l0a && access.access.l0a.l0a_a) || (access.access.l0a && access.access.l0a.l0a_b)) {
                if (viewHash === 'Project Management') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push('Project Management')

                if (viewHash === 'Model Category') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push('Model Category')

                if (viewHash === 'Model Store') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push('Model Store')
            }
        }

        // accessBuild.push('Animation Type')

        if (userData.manage_user) {
            if (viewHash === 'Manage User') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Manage User')
        }


        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView,
            })
        }
    }

    render() {
        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({
                openDrawer: true,
            })
        };

        const handleDrawerClose = () => {
            this.setState({
                openDrawer: false,
            })
        };

        const handleViewChange = (e, val) => {
            if (!getCookie("bb_metaverse_token")) {
                window.location.href = '/'
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        const darkMode = () => {
            if (localStorage.getItem("theme_mode")) {
                localStorage.removeItem("theme_mode");
            } else {
                localStorage.setItem("theme_mode", 'dark')
            }
            window.location.reload()
        }

        // const Logout = () => {
        //     deleteCookie("bb_metaverse_user")
        //     deleteCookie("bb_metaverse_token")
        //     window.location.href = '/'
        // }

        // const changePassword = () => {
        //     this.setState({
        //         passwordDialog: true
        //     })
        // }

        const handleDialogClose = () => {
            this.setState({
                passwordDialog: false
            })
        }

        return (
            <>{this.state.viewClass.length === 0 ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <Typography>
                        You do not have access to any tabs
                    </Typography>
                </Box>
                :
                <>
                    {(this.state.isLoading) ? (
                        <LoopCircleLoading color={getPrimaryColor()} />
                    ) : (
                        <div className={classes.root}>
                            <CssBaseline />
                            <AppBar
                                position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: this.state.openDrawer,
                                })}
                            >
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        className={clsx(classes.menuButton, {
                                            [classes.hide]: this.state.openDrawer,
                                        })}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" noWrap className={classes.title}>
                                        BBverse
                                    </Typography>
                                    <Box style={{ marginRight: "15px", cursor: 'pointer' }} onClick={() => this.openContactorAbout(true)}>
                                        About
                                    </Box>
                                    <Tooltip title="Contact" arrow>
                                        <ContactsIcon sx={{ mr: 2, fontSize: '25px', cursor: 'pointer' }} onClick={() => this.openContactorAbout(false)} />
                                    </Tooltip>
                                    <Tooltip title="Dark Mode" arrow>
                                        <Brightness4Icon sx={{ mr: 2, fontSize: '25px', cursor: 'pointer' }} onClick={darkMode} />
                                    </Tooltip>
                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                        {(popupState) => (
                                            <div>
                                                <Avatar  {...bindTrigger(popupState)} sx={{ backgroundColor: "secondary.main" }}>{this.state.userData.first_name.charAt(0) + this.state.userData.last_name.charAt(0)} </Avatar>
                                                <Popover {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}>
                                                    <Box sx={{ width: 280, m: 1, p: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', gap: 4 }}>
                                                        <Avatar sx={{ backgroundColor: "primary.main", width: 80, height: 80 }}>{this.state.userData.first_name.charAt(0) + this.state.userData.last_name.charAt(0)}</Avatar>
                                                        <Typography>{this.state.userData.first_name + " " + this.state.userData.last_name}</Typography>
                                                        <Typography variant="caption">{this.state.userData.email}</Typography>
                                                        {/* <Box sx={{ width: '100%', display: "flex", flexDirection: "row-reverse", }}>
                                                            <Button variant="text" size='small' color="primary" onClick={Logout}>
                                                                Log Out
                                                            </Button>
                                                            <Button variant="text" size='small' color="primary" onClick={changePassword}>
                                                                Change Password
                                                            </Button>
                                                        </Box> */}
                                                    </Box>
                                                </Popover>
                                            </div>
                                        )}
                                    </PopupState>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                variant="permanent"
                                className={clsx(classes.drawer, {
                                    [classes.drawerOpen]: this.state.openDrawer,
                                    [classes.drawerClose]: !this.state.openDrawer,
                                })}
                                classes={{
                                    paper: clsx({
                                        [classes.drawerOpen]: this.state.openDrawer,
                                        [classes.drawerClose]: !this.state.openDrawer,
                                    }),
                                }}
                            >
                                <div className={classes.toolbar}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <Divider />
                                <List>
                                    {this.state.menus.map((text, index) => (
                                        <Tooltip title={text} aria-label={text} key={text}>
                                            <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                <ListItemIcon>
                                                    {
                                                        text === "Project Management" ? <AssignmentIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                            text === "Manage User" ? <PersonAddIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                text === "My Projects" ? <ListAltIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                    text === "Model Category" ? <CollectionsBookmarkIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                        text === "Project Store" ? <StorefrontIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> :
                                                                            text === "Model Store" ? <LocalShippingIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />
                                                                                // : text === "Animation Type" ? <AssignmentTurnedInIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> 
                                                                                : <AssignmentIcon />
                                                    }</ListItemIcon>
                                                <ListItemText primary={text} />
                                            </StyledListItem>
                                        </Tooltip>

                                    ))}
                                </List>
                            </Drawer>
                            <main className={classes.content} style={{ padding: '30px' }}>
                                <div className={classes.toolbar} />
                                {
                                    (this.state.viewClass === "Manage User") ? (
                                        <User />
                                    ) : (this.state.viewClass === "Project Management") ? (
                                        <ProjectManagement />
                                    ) : (this.state.viewClass === "My Projects") ? (
                                        <MyProjectList />
                                    ) : (this.state.viewClass === "Project Store") ? (
                                        <OpenProjectStore />
                                    ) : (this.state.viewClass === "Model Category") ? (
                                        <ModelCategory />
                                    ) : (this.state.viewClass === "Model Store") ? (
                                        <ModelStore />
                                    )
                                        // : (this.state.viewClass === "Animation Type") ? (
                                        //     <AnimationType />
                                        // ) 
                                        : (null)
                                }
                            </main>

                        </div>
                    )
                    }


                    <Dialog fullWidth={true} maxWidth="xs" disableEnforceFocus open={this.state.passwordDialog} onClose={handleDialogClose} >
                        <DialogTitle>Change Password</DialogTitle>
                        <DialogContent>
                            <ChangePassword close={handleDialogClose} email={this.state.userData.email} />
                        </DialogContent>
                    </Dialog>

                    <Dialog maxWidth={this.state.isAbout ? 'md' : 'xs'} disableEnforceFocus open={this.state.aboutorContactDialog} onClose={() => this.setState({ aboutorContactDialog: false })} >
                        <DialogTitle>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                <Box flexGrow={1} >
                                    <Typography variant="h6" >
                                        {this.state.isAbout ? 'About' : 'Contact'}
                                    </Typography>
                                </Box>
                                <IconButton onClick={() => this.setState({ aboutorContactDialog: false })}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            {this.state.isAbout ? <div>
                                <b>Scope:</b><br />
                                Two modes of delivery vision is agreed.<br />
                                1. <b>Service Delivery:</b> Project teams will provide the scope, design brief including any site scan, and models information available to Digital Team to create further required models, collate models, integrate programme (p6, asta, excel, MS project, etc), to either develop a 3D or 4D sequence to output in video or synchro or fuzor or XR or AR or VR app
                                The outputs will be distributed via BBverse platform for the project users (unlimited without requirement for any new software installations or licensing cost)<br />

                                2. <b>Self Service Delivery:</b> The application will have functionality for the project users to import 3D models, programme, machineries/equipments, and develop a sequence collaboratively between multiple discipline stakeholders within BB, and to manage the project workspace <br />
                                For this Proof Of Concept (POC), The Digital Rehearsal application, scope is limited.<br />

                                This POC UAT 1 is part of the Service Delivery model, and not all functions are developed yet. Users are provided with minimum required functionalities to test the Proof Of Concept output,<br />
                                to provide feedback, and define further requirements which help IT to assess if the application can be further developed, to realise potential benefits.<br /><br />

                                <b>Scope limited for this POC UAT 1 :</b><br />
                                1.User can experience the sample ( A57 ) serviced interactive 4D construction sequence<br />
                                2.Play/Pause the sequence at any given date<br />
                                3.Navigate, rotate and zoom in to inspect the elements of work<br />
                                4.Introspect dates & active tasks<br />
                                5.Provide quick reviews of the output. As its cloud based real time, any stakeholder within the project can view other reviews, status, comments to track<br />
                                6.Examining metadata of 3D models (as of now limited only to model design information)<br />
                                7.Visualize the clearance of space effectively, collaborate with site team for clashes, safety requirements<br />
                                8.Interact with the Timeline to run back / forth the 4D sequence<br />
                                9.This A57 project terrain is a direct import of drone survey in site scan. Offline functionalities are available to change the site scan imagery from different dates of drone surveys<br />
                            </div> :

                                <div>For Service Delivery for one of your projects,<br />
                                    contact : tom.bush@balfourbeatty.com <br />
                                    suresh.rajamanikkam@balfourbeatty.com <br/>
                                    UK & EDC coordinators for Digital & Animation <br /><br />

                                    For technical issues in BBverse, contact <br />
                                    venkata.rathinam@balfourbeatty.com<br/>
                                    </div>}
                        </DialogContent>
                    </Dialog>
                </>
            }
            </>
        )
    }
}


export default withStyles(useStyles)(withSnackbar(Home));