import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar, withSnackbar } from 'notistack';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { blobDomain, blobSAS } from '../Helper/BlobHelper';
import { Grid } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { screenSizeCheck } from '../Planner/KanbanHelper';

var token = getCookie("bb_metaverse_token");

const ModelStore = () => {

    const [data, setData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [cardsLoading, setCardsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const modelRef = useRef();
    const [url, setUrl] = useState('')

    function getData(category) {
        setCardsLoading(true)
        let data = categoryData
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.id === category.id) {
                element.expanded = !element.expanded
            } else {
                element.expanded = false
            }
        }
        setCategoryData(data)
        Service.get(`/api/modular/object/version/get_modular_object/?modular_category=${category.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)
            setCardsLoading(false)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setCardsLoading(false)
        }
        )
    }


    function getCategoryData() {
        Service.get('/api/modular/category/', {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                element.expanded = false
            }
            setCategoryData(res.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    useEffect(() => {
        getCategoryData()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function gltfViewer(url) {
        if (url) {
        setUrl(blobDomain() + url + "?" + blobSAS())
        setOpen(true)
        } else {
            enqueueSnackbar('No Versions have been uploaded for this Model', {
                variant: 'warning'
            })
        }
    }

    return (
        <div>{isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box> :
            <div>{categoryData.map((category, index) => {
                return (
                    <Accordion expanded={category.expanded} onChange={() => getData(category)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{category.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cardsLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><CircularProgress /></Box> :
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {data.map((data, index) => {
                                        return (
                                            <Grid item xs={window.innerWidth <= 1650 ? 4 : 3} key={index} >
                                                <Card elevation={8} style={{ borderRadius: '10px' }}>
                                                    <CardActionArea onClick={() => gltfViewer(data.object_source)} >
                                                        <img src={blobDomain() + data.thumbnail + "?" + blobSAS()} alt='' style={{ height: "200px", width: "100%", objectFit: 'cover' }} />
                                                        <Box sx={{ m: 1 }}>
                                                            <Box sx={{ mr: 1, fontWeight: "bold", fontSize: "large" }}>
                                                                {data.name}
                                                            </Box>
                                                            <Box sx={{ mt: 1, mr: 0.5, textOverflow: 'ellipsis', overFlow: 'hidden' }}>
                                                                {data.description}
                                                            </Box>
                                                        </Box>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                                </Grid>}
                        </AccordionDetails>
                    </Accordion>)
            })}</div>
        }
            <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <div >
                            <model-viewer
                                style={{
                                    width: screenSizeCheck() ? '400px' : '600px',
                                    height:  screenSizeCheck() ? '400px' : '600px'
                                }}
                                src={url}
                                alt=""
                                auto-rotate
                                camera-controls
                                shadow-intensity="1"
                                ref={(ref) => {
                                    modelRef.current = ref;
                                }}
                            >
                            </model-viewer>
                        </div>
                    </Box>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default withSnackbar(ModelStore);