import { Box, Button, CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { getCookieNoCrypto, setCookieNoCrypto } from "../Utils/CookieHelper";
import { msClientId } from "../Login/authProvider";
import { encryptJWT, jwtEncryptKey } from "../App";

// const token = getCookie("bb_metaverse_token");
// const user = JSON.parse(getCookie("bb_metaverse_user"));
// var access = JSON.parse(getCookie("bb_metaverse_access"));

function WEBGLPlayer(props) {


    const canvasRef = useRef(null)
    // const [vrEnable, setVrEnable] = useState(false)

    const { unityProvider, requestFullscreen, loadingProgression, isLoaded, unload } = useUnityContext(props.url);
    const [tempCokkie, setTempCokkie] = useState(true);

    useEffect(() => {
        // if (navigator.xr) {
        //     navigator.xr.isSessionSupported("immersive-vr").then((isSupported) => {
        //       if (isSupported) {
        //         setVrEnable(true)
        //       }
        // }

        //     navigator.xr.isSessionSupported("immersive-ar").then((isSupported) => {
        //         if (isSupported) {
        //           setArEnable(true)
        //         }
        //       });
        //   }
    }, [])



    async function unLoadApp() {
        if (isLoaded) {
            await unload()
        }
        props.close()
    }

    if (isLoaded && tempCokkie) {
        setTempCokkie(false)

        console.log("msal." + msClientId + ".idtoken")

        console.log(getCookieNoCrypto("msal." + msClientId + ".idtoken"))

        console.log(jwtEncryptKey())

        console.log(encryptJWT("D1R1P" + getCookieNoCrypto("msal." + msClientId + ".idtoken") + "D1R1P", jwtEncryptKey()))

        setCookieNoCrypto('bb_metaverse_msal_tempdr', encryptJWT("D1R1P" + getCookieNoCrypto("msal." + msClientId + ".idtoken") + "D1R1P", jwtEncryptKey()), { secure: true, 'max-age': 25, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);
        // setCookieNoCrypto('bb_metaverse_token_tempdr', token, { secure: true, 'max-age': 10, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);
        // setCookieNoCrypto('bb_metaverse_user_tempdr', JSON.stringify(user), { secure: true, 'max-age': 10, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);
        // setCookieNoCrypto('bb_metaverse_access_tempdr', JSON.stringify(access), { secure: true, 'max-age': 10, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
        if (props.assetData) {
            setCookieNoCrypto('bb_metaverse_version_tempdr', JSON.stringify(props.assetData), { secure: true, 'max-age': 25, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
        }
    }

    return <div>

        {!isLoaded && (
            <Box display='flex' flexDirection={'column'} alignItems={'center'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box marginRight={1}><CircularProgress size={15} /></Box>
                    <p>Loading Application...</p>
                </Box>
                <Box display="flex" alignItems="center" flexDirection={'row'} width="100%">
                    <Box width="100%" mr={1}>
                        <LinearProgress variant="determinate" value={Math.round(loadingProgression * 100)} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="primary">{`${Math.round(loadingProgression * 100,)}%`}</Typography>
                    </Box>
                </Box>
            </Box>
        )}

        <Box display='flex' flexDirection={'row'}  width='100%'>
            <Box width='100%' flexGrow={1}>
                <Unity unityProvider={unityProvider}
                    ref={canvasRef}
                    style={{ height: window.innerHeight * (isLoaded ? .75 : 0), width: window.innerWidth * .75 }}
                    tabIndex={1}
                    devicePixelRatio={window.devicePixelRatio}
                />
            </Box>
            
            </Box>
            <Box display={'flex'} justifyContent={'center'}  alignItems={'center'}>
                {isLoaded ? <div>
                    <Button size="small"  variant="contained" color="primary" onClick={() => requestFullscreen(true)}>Fullscreen</Button>
                    <div>
                        {/* {vrEnable  ? */}
                        {/* <Button style={{ marginTop: "15px" }} variant="contained" color="primary" onClick={() => UNSAFE__unityInstance.Module.WebXR.toggleVR()}>{"VR"}</Button>  */}
                        {/* : null */}
                        {/* } */}
                    </div>
                </div> : null}
                <Box marginLeft="5px">
                <Button size="small"  variant="contained" color="primary" onClick={() => { unLoadApp() }}>{isLoaded ? "Exit" : "Cancel Download"}</Button>
                </Box>
        </Box>
    </div>



}

export default WEBGLPlayer