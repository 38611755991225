import { Box, Button } from '@mui/material';
import React from 'react';
import { getCookie } from '../Utils/CookieHelper';
import Service from '../Networking/NetworkingUtils';
import { useSnackbar } from "notistack";
import { TextField } from '@material-ui/core';


const token = getCookie("bb_metaverse_token");

const ChangePassword = (props) => {
    const [passwordData, setPasswordData] = React.useState({})
    const [misMatchWarning, setMisMatchWarning] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [disabled, setDisabled] = React.useState(false);



    const passwordSubmit = () => {

        if (!passwordData.new_password || !passwordData.new_password_check || !passwordData.old_password) {
            setMisMatchWarning(false)
            enqueueSnackbar('Please enter the empty fields', {
                variant: 'warning'
            })
        }
        else if (passwordData.new_password === passwordData.old_password) {
            setMisMatchWarning(false)
            enqueueSnackbar('Old and New Password are same. Please try another', {
                variant: 'warning'
            })
        } else if (passwordData.new_password !== passwordData.new_password_check) {
            setMisMatchWarning(true)
        }
        else {
            setDisabled(true)
            setMisMatchWarning(false)
            let obj = {
                "new_password": passwordData.new_password,
                "old_password": passwordData.old_password,
                "email": props.email
            }

            Service.post('/api/users/reset_password/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj
            }).then(res => {
                setDisabled(false)
                enqueueSnackbar('Password Changed Successfully', {
                    variant: 'success'
                })
                props.close(false)
            }).catch(error => {
                console.log(error);
                setDisabled(false)
                if (error.status === 406) {
                enqueueSnackbar('Old Password that you entered is not valid!', {
                        variant: 'warning'
                })
               
            }else{
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })

            }
            })
        }
    }

    const handleChange = (event) => {
        if (event) {
            let postData = passwordData
            postData[event.target.id] = event.target.value
            setPasswordData({ ...passwordData, ...postData })
        }
    };



    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <TextField variant='outlined' id="old_password" type="password" fullWidth label='Old Password' onChange={handleChange} style={{ marginTop: '10px' }}></TextField>
                <TextField variant='outlined' id="new_password" autoComplete='off' fullWidth label='New Password' style={{ marginTop: '10px' }} onChange={handleChange}></TextField>
                <TextField variant='outlined' id="new_password_check" type="password" fullWidth label='Confirm New Password' style={{ marginTop: '10px' }} onChange={handleChange}></TextField>
                {misMatchWarning ? <Box sx={{ color: 'red' }}>New Password and Confirm New Password must be same</Box> : null}
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', mt: 1 }}>
                    <Button disabled={disabled} variant="contained" color="primary" onClick={() => passwordSubmit()}>Submit</Button>
                </Box>
            </Box>
        </div>
    )
}

export default ChangePassword