import React, { useEffect, useState } from 'react'
import Service from '../Networking/NetworkingUtils'
import MaterialTable from 'material-table'
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar, withSnackbar } from 'notistack';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Slide, Typography } from '@material-ui/core';
import Blob from '../Blob';
import { DropzoneArea } from 'material-ui-dropzone';
import { blobWriteString } from '../Helper/BlobHelper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

var token = getCookie("bb_metaverse_token");
const user = JSON.parse(getCookie("bb_metaverse_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModelVersion = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [openUpload, setOpenUpload] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [files, setFiles] = React.useState(null)
    const [progress, setProgress] = React.useState(0)
    const [thumbnailUploadDialog, setThumbnailUploadDialog] = useState(false)
    const [loading, setLoading] = useState(false)   

    const getData = () => {
        Service.get(`/api/modular/object/version/?modular_object=${props.model.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    const handleUploadDialog = () => {
        setSelectedRow(null)
        setThumbnailUploadDialog(false)
        setSubmitDisabled(true)
    }

    const postData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        data.modular_object = props.model.id
        Service.post('/api/modular/object/version/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        }).then(res => {
            getData()
            enqueueSnackbar('Model added Successfully!', {
                variant: 'success'
            })
        }).catch(error => {
            console.log(error)
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
            setIsLoading(false)
        }
        )
    }

    useEffect(() => {
        console.log(props)
        getData()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editData = (data) => {
        setIsLoading(true)
        data.created_by = user.id
        data.modular_object = props.model.id
        Service.put(`/api/modular/object/version/` + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                enqueueSnackbar('Model edited Successfully', {
                    variant: 'success'
                });
                getData()
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    const UpdateBlob = (data, row) => {
        if (row && data.length > 0) {
            row.object_source = data.join()
            row.created_by = user.id
            row.id = selectedRow.id
            Service.put('/api/modular/object/version/' + row.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: row
            }).then(res => {
                getData()
                enqueueSnackbar('Blob Uploaded Successfully!', {
                    variant: 'success'
                })
                setOpenUpload()
            }).catch(error => {
                console.log(error)
                enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
        }
    }

    const handleClose = () => {
        setOpenUpload(false)
    }

    function UpdateThumbnail(thumbnail, rowData) {
        setIsLoading(true)
        rowData.thumbnail = thumbnail
        Service.put('/api/modular/object/version/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: rowData
        }).then(res => {
            enqueueSnackbar('Thumbnail added successfully!', {
                variant: 'success'
            })
            setSelectedRow(null)
            setThumbnailUploadDialog(false)
            getData()
        }).catch(error => {
            console.log(error);
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        })
    }

    const uploadFiles = async () => {
        const { BlobServiceClient } = require("@azure/storage-blob");
        window.Buffer = require("buffer").Buffer;

        const blobSasUrl = blobWriteString()
        const blobServiceClient = new BlobServiceClient(blobSasUrl);

        const containerName = "model-lib/BBVMODEL" + props.model?.id;

        const containerClient = blobServiceClient.getContainerClient(containerName);

        try {
            const promises = [];
            setLoading(true)
            for (const file of files) {
                var file_extension = file.name.split('.').pop()
                var file_name = props.model?.id + "_" + selectedRow?.id + "_thumbnail." + file_extension
                const blockBlobClient = containerClient.getBlockBlobClient(file_name);
                promises.push(blockBlobClient.uploadData(file, {
                    onProgress: ev => {
                        setProgress((ev.loadedBytes / file.size) * 100)
                    }
                }));
            }
            await Promise.all(promises);
            setLoading(false)
            UpdateThumbnail(containerName + "/" + file_name, selectedRow)
            // this.props.updateBlob(file_name_array, this.props.version)
        }
        catch (error) {
            console.log(error.message);
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        }
    }


    return (
        <div>
            <MaterialTable
                title="Model Version"
                columns={[
                    { title: 'Name', field: 'name' , defaultSort: 'asc'},
                    { title: 'Description', field: 'description' },
                    { title: 'Version Code', field: 'version_code' },
                    { title: 'Date Created', field: 'date_created', type: 'date' },
                    { title: 'Is Active', field: 'is_active', type: 'boolean' },
                ]}
                isLoading={isLoading}
                data={data}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            postData(newData)
                            // setTimeout(() => {
                            resolve();
                            // }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            // setTimeout(() => {
                            editData(newData)
                            resolve();
                            // }, 1000)
                        }),
                }}
                actions={[
                    rowData => ({
                        icon: 'upload',
                        tooltip: 'Upload',
                        disabled: rowData.object_source,
                        onClick: (row) => { setSelectedRow(rowData); console.log(rowData); console.log(row); setOpenUpload(true) }
                    }),
                    rowData => ({
                        icon: () => <CloudUploadIcon />,
                        tooltip: 'Upload Thumbnail',
                        // disabled: rowData.object_source,
                        onClick: (row) => { setThumbnailUploadDialog(true);setSelectedRow(rowData) }
                    })
                ]}
                options={{
                    padding: "dense",
                    paging: false,
                    filtering: true,
                    actionsColumnIndex: -1
                }}
            />
            <Dialog maxWidth="lg" fullWidth={true} open={openUpload}  >
                <DialogTitle marginTop="2px" spacing={2}>Upload Blob</DialogTitle>
                <DialogContent>
                    <Blob
                        containerName={"model-lib/BBVMODEL" + props.model?.id}
                        fileName={props.model?.id + "_" + selectedRow?.id}
                        project={props.project}
                        asset={props.data}
                        version={selectedRow}
                        onClose={handleClose}
                        updateBlob={UpdateBlob}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="lg"
                fullWidth={true}
                TransitionComponent={Transition}
                open={thumbnailUploadDialog}
                onClose={handleUploadDialog}>
                <DialogTitle>Upload Thumbnail</DialogTitle>
                <DialogContent>
                    <DropzoneArea
                        filesLimit={1}
                        maxFileSize={10737418240}
                        onChange={(files) => setSubmitDisabled(!(files.length > 0))}
                        onDrop={(files) => setFiles(files)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={() => handleUploadDialog()}>
                        close
                    </Button>
                    <Button disabled={submitDisabled} color='primary' variant='contained' onClick={() => uploadFiles()}>
                        submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth="lg" fullWidth={true} open={loading}  >
                <DialogTitle marginTop="2px" spacing={2}>Uploading...</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="primary">{`${Math.round(
                                progress,
                            )}%`}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default withSnackbar(ModelVersion)