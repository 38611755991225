import React, { useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { Button, DialogTitle, TextField, DialogContent, Dialog } from '@material-ui/core';
import { Toolbar, DialogActions, Tooltip } from '@mui/material';
import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment';
import { useSnackbar, withSnackbar } from "notistack";
import { LoopCircleLoading } from 'react-loadingg';
import DownloadIcon from '@mui/icons-material/Download';
import { json2excel } from 'js2excel';
import Row from './Row';

const token = getCookie("bb_metaverse_token");
var access = JSON.parse(getCookie("bb_metaverse_access"));
var user = JSON.parse(getCookie('bb_metaverse_user'))

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ProjectManagement = forwardRef((props, ref) => {
  const [row, setRow] = React.useState([]);
  const [dialogopen, setDialogopen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [projectThumbnail, setProjectThumbnail] = React.useState('');
  const classes1 = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [addDisabled, setAddDisabled] = React.useState(false)

  function handleClick() {
    setDialogopen(true)
  }
  function handleClose() {
    setDialogopen(false)
  }

  function Submit(e) {
    if (name.length === 0 || desc.length === 0) {
      enqueueSnackbar('Entries are not completely filled', {
        variant: 'warning'
      })
      e.preventDefault();
      setName("");
      setDesc("");
    } else {
      setAddDisabled(true)
      const dataobj = {};
      dataobj["name"] = name;
      dataobj["description"] = desc;
      dataobj['project_thumbnail'] = projectThumbnail;
      dataobj['created_by'] = user.id
      setDialogopen(false)

      Service.post('/api/projects/', {
        headers: {
          Authorization: "Token " + token
        },
        data: dataobj
      })
        .then(res => {
          enqueueSnackbar('Data added successfully!', {
            variant: 'success'
          })
          getData();
          setAddDisabled(false)


        })
        .catch(error => {
          setAddDisabled(false)
          console.log(error);
          enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          })
        });
    }
    setName("");
    setDesc("");
    setProjectThumbnail("");
  }

  function getData() {
    let url = ''
    if (access && access.access && access.access.l0a.l0a_b) {
      url = '/api/projects/'
    } else {
      url = `/api/projects/get_project/`
    }
    Service.get(url, {
      headers: {
        Authorization: "Token " + token,
      },
    }).then(res => {
      setRow(res.data)
      setLoading(false)
    }).catch(error => {
      console.log(error)
      enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      })
    })
  }

  useEffect(() => {
    if (access) {
      access = JSON.parse(getCookie("bb_metaverse_access"));
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleData() {
    getData()
  }

  function handleDownload() {

    let excelDataArray = JSON.parse(JSON.stringify(row));
    let jsonArray = []

    for (let i = 0; i < excelDataArray.length; i++) {
      let json = {}
      const element = excelDataArray[i];
      if (element) {

        json["Name"] = element.name
        json["Description"] = element.description
        json["Date created"] = moment(element.date_created).format("DD-MM-YYYY")
        json["Project Thumbnail"] = element.project_thumbnail
        json["Created by"] = element.last_name + ', ' + element.first_name

        jsonArray.push(json)
      }
    }

    try {
      json2excel({
        data: jsonArray,
        name: "Projects",
        formateDate: 'dd/mm/yyyy'
      });
    } catch (e) {
      console.error('export error');
    }
  }

  return (

    <div>
      {loading ? <LoopCircleLoading /> :
        <Paper className={classes1.paper}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", m: 0, }}>
            <Typography variant="h5">Projects</Typography>
            <Box>
              {(access && access.access && access.access.l0a.l0a_b) ? <Button disabled={addDisabled} onClick={handleClick}><Tooltip title="Add"><AddBoxIcon /></Tooltip></Button> : null}

              {(access && access.access && access.access.l0a.l0a_b) ? <Button onClick={handleDownload}><Tooltip title="Download"><DownloadIcon /></Tooltip></Button> : null}

            </Box>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" style={{ tableLayout: 'center' }}>
              <TableHead>
                <TableRow align="center">
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  {/* <TableCell align="left">Thumbnail</TableCell> */}
                  <TableCell align="left">Date Created</TableCell>
                  <TableCell align="left">Created By</TableCell>
                  <TableCell align="left">{access && access.access && access.access.l0a.l0a_b ? "Actions" : ""}</TableCell>
                </TableRow>
              </TableHead>
              {row.length === 0 ?
                <TableRow sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '2px' }}>
                  <TableCell align="center" colSpan={6}>
                    No Projects to Display
                  </TableCell>
                </TableRow >
                :

                row.map((row) => (
                  <TableBody>
                    <Row key={row.name} row={row} getProject={handleData} />
                  </TableBody>
                ))
              }
            </Table>
          </TableContainer>
        </Paper>

      }
      <Dialog maxWidth="xs" fullWidth={true} open={dialogopen} onClose={handleClose} >
        <DialogTitle marginTop="2px" spacing={2}>Add Project</DialogTitle>
        <DialogContent style={{ gap: "12px", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
          <TextField fullWidth autoComplete="off" variant="outlined" label="Name" onChange={(e) => { setName(e.target.value) }}></TextField>
          <TextField fullWidth autoComplete="off" variant="outlined" label="Description" onChange={(e) => setDesc(e.target.value)}></TextField>
          {/* <TextField fullWidth multiline minRows={3} autoComplete="off" variant="outlined" label="Project Thumbnail" onChange={(e) => setProjectThumbnail(e.target.value)}></TextField> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setDialogopen(false)}>Cancel</Button>
          <Button color="primary" variant="text" onClick={(e) => Submit(e)}>Add</Button>
        </DialogActions>

      </Dialog>

    </div>
  );
})
export default withSnackbar(ProjectManagement);
