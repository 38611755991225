import React, { Component } from 'react'
import { Avatar, Box, Card, CardActions, Divider, IconButton, Link, Tooltip } from '@material-ui/core';
import Slider from "react-slick";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import WebIcon from '@material-ui/icons/Web';
import MultiProgress from "react-multi-progress";
import { getCookie } from '../Utils/CookieHelper';
import Service from '../Networking/NetworkingUtils';
import { dateCheck, getCardWidth, getTag, screenSizeCheck } from './KanbanHelper';
import { getGreencolor, getMode, getOrangecolor, getPrimaryColor, getProgBgColor, getSecondaryColor } from '../Helper/CommonThings';

const token = getCookie("bb_metaverse_token");

const useStyles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        backgroundColor: getMode() ? getSecondaryColor() : getPrimaryColor(),
        color: getMode() ? getPrimaryColor() : '#fff'
    },
});

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

class CustomCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: this.props.props.users,
            suggested_users: this.props.props.suggested_users,
            iconDisabled: false,
            users_copy: this.props.props.users,
            updated_card: null,
        }
    }

    updatedResponse(popOverState) {
        popOverState.close()
        if (this.state.updated_card) {
            this.props.userUpdate(this.state.updated_card)
        }
    }

    addUser(value) {
        this.setState({ iconDisabled: true })
        Service.get(`/api/work/order/add_user/?user_id=${value.user_id}&card_id=${this.props.props.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    users: res.data.users,
                    suggested_users: res.data.suggested_users,
                    iconDisabled: false,
                    updated_card: res.data
                }, () => {
                    this.props.userUpdate(this.state.updated_card)
                })

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                this.setState({ iconDisabled: false })
            });
    }

    removeUser(value) {
        this.setState({ iconDisabled1: true })
        Service.get(`/api/work/order/remove_user/?user_id=${value.user_id}&card_id=${this.props.props.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    users: res.data.users,
                    suggested_users: res.data.suggested_users,
                    iconDisabled1: false,
                    updated_card: res.data
                }, () => {
                    this.props.userUpdate(this.state.updated_card)
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
                this.setState({ iconDisabled1: false })
            });
    }

    removeCard() {
        this.props.props.onDelete()
        Service.get(`/api/work/order/archived/?card_id=${this.props.props.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Task Deleted Successfully', {
                    variant: 'success'
                })
                this.props.props.onDelete()

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    render() {
        const classes = this.props.classes

        const settings = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 4 : 6,
            slidesToScroll: screenSizeCheck() ? 4 : 6,
            speed: 300,
            rows: screenSizeCheck() ? 1 : 1,
            arrows: true,
            nextArrow: <SampleNextArrow data={screenSizeCheck() ? 5 : 6} />,
            prevArrow: <SamplePrevArrow data={screenSizeCheck() ? 5 : 6} />,
            adaptiveHeight: true,
            accessibility: true,
        };

        return (
            <>
                <Card id='card_id' style={{ margin: '5px', width: getCardWidth(this.props.data.lanes.length) + "px", cursor: 'pointer' }} onClick={(event) => {
                    this.props.dialogueData(this.props.props)
                }}>

                    <Box display='flex' flexDirection='row' margin={1} >
                        <Box flexGrow={1} marginLeft='10px' >
                            {
                                this.props.user_id !== null || this.props.todaySummary !== null || this.props.teamSummary !== null ? <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '11px' }}>{this.props.props.tender_name} </div> : null
                            }
                            {
                                this.props.user_id !== null || this.props.todaySummary !== null || this.props.teamSummary !== null ?
                                    <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '11px' }} >
                                        <Link
                                            color={getMode() ? 'secondary' : 'primary'}
                                            onClick={(e) => { e.stopPropagation(); window.open("/tendersubtasks/" + this.props.props.ohlwbs_tasks) }}>
                                            {(!this.props.props.task_decs) ? this.props.props.task_name : this.props.props.task_decs}
                                        </Link>
                                    </div>
                                    : null
                            }
                            {
                                this.props.todaySummary !== null || this.props.teamSummary !== null ?
                                    <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }}>{this.props.props.main_task_name}</div> : null
                            }
                            <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}>{this.props.props.title}</div>

                        </Box>
                        <Box flexWrap='wrap' >
                            <IconButton size='small' onClick={(event) => { event.stopPropagation(); this.removeCard() }} ><CloseIcon fontSize='small' /></IconButton>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent='space-around' margin={1}>
                        <Box display='flex' flexDirection={'row'} alignItems='center' style={{ borderRadius: '5px', backgroundColor: dateCheck(this.props.props.planned_start, this.props.props.planned_end, this.props.props), padding: '3px' }}>
                            <DateRangeIcon fontSize='small' />
                            <Tooltip title={<div>Planned Start : {this.props.props.planned_start}<br /> Planned End : {this.props.props.planned_end}<br /></div>}>
                                <Box fontSize='12px' marginLeft='2px' alignItems='center'>{moment(this.props.props.planned_start).format("DD/MM") + " - " + moment(this.props.props.planned_end).format("DD/MM")}</Box>
                            </Tooltip>
                        </Box>

                        <Box display='flex' flexDirection={'row'} alignItems='center' >
                            <CommentIcon fontSize='small' />
                            <Box fontSize='12px' marginLeft='2px'>{this.props.props.comments_count}</Box>
                        </Box >

                        <Box style={{
                            marginLeft: '15px', fontSize: '10px', borderRadius: '3px', boxSizing: 'border-box', border: '1px solid ' + getTag(this.props.props.priority)?.color,
                            color: getMode() ? 'white' : 'black',
                            padding: '2px'
                        }} display='flex' flexDirection={'row'} alignItems='center'>
                            <Box color={getTag(this.props.props.priority)?.color}>{getTag(this.props.props.priority)?.icon}</Box>
                            <Box fontSize='11px' marginLeft='2px' marginRight='5px'>{getTag(this.props.props.priority)?.name}</Box>
                        </Box>
                    </Box>
                    {this.props.subTaskData === null && !this.props.teamSummary && !this.props.todaySummary ?
                        <Box margin={1} display='flex' flexDirection='row' justifyContent='center' alignItems='center' cursor='pointer'
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.openSubTender(this.props.props)
                            }}>
                            <Tooltip title="Planner">
                                <IconButton size='small' >
                                    <WebIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <Box
                                flexGrow={1}
                                justifyContent='center'
                                alignItems='center'
                                borderRadius={1}
                                marginRight='5px'
                                cursor='pointer'
                            >
                                <MultiProgress
                                    transitionTime={1.2}
                                    elements={[
                                        {
                                            value: (this.props.props.subtasks_done / this.props.props.subtasks_total) * 100,
                                            color: getGreencolor(),
                                        },
                                        {
                                            value: 100 - ((this.props.props.subtasks_done / this.props.props.subtasks_total) * 100),
                                            color: getOrangecolor(),
                                        },

                                    ]}
                                    height={7}
                                    backgroundColor={getProgBgColor()}
                                />
                                {/* <SubTenderProgressBar subtasks_done={this.props.props.subtasks_done} subtasks_total={this.props.props.subtasks_total} /> */}
                            </Box>
                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'}> {this.props.props.subtasks_done} of {this.props.props.subtasks_total} completed</Box>
                        </Box> : null}
                    <Divider variant="middle" />
                    <CardActions >
                        <Box
                            onClick={((e) => e.stopPropagation())}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 1,
                                width: (getCardWidth(this.props.data.lanes.length) - 90) + "px",
                                marginLeft: '20px',
                                cursor: 'pointer'
                            }}>
                            <Slider {...settings}>
                                {this.state.users.map((name, i) =>
                                    <Box
                                        key={i}
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            width: '100%',
                                        }} onClick={() => this.props.dialogueData(this.props.props)}>
                                        <Tooltip title={name.last_name + ", " + name.first_name}>
                                            <Avatar sx={{ bgcolor: 'primary' }} className={classes.small}><Box fontWeight={'bold'} fontSize={'10px'}  >{name.first_name.charAt(0) + name.last_name.charAt(0)}</Box></Avatar>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Slider>
                        </Box>
                        <Box onClick={((e) => e.stopPropagation())}>
                            <PopupState variant="popover" popupId="demo-popup-popover">
                                {(popupState) => (
                                    <div>
                                        <IconButton size='small' style={{ marginLeft: '15px' }} {...bindTrigger(popupState)}>
                                            <GroupAddIcon fontSize='small' />
                                        </IconButton>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={() => this.updatedResponse(popupState)}
                                        >
                                            <Box m={2} width={300} maxHeight={screenSizeCheck() ? 500 : 600}>
                                                <Box display={'flex'} flexDirection={'column'}>
                                                    {/* <Box width={'100%'}><TextField id="standard-basic" label="Type a name" style={{ width: '100%' }} /></Box> */}
                                                    <Box >
                                                        <Box fontWeight={'bold'}>Assigned</Box>
                                                        <Box mt={1}>
                                                            {this.state.users.length !== 0 ?
                                                                <List dense>
                                                                    {this.state.users.map((value, i) =>
                                                                        <ListItem key={i}>
                                                                            <ListItemAvatar>
                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={value.last_name + ", " + value.first_name}
                                                                            />
                                                                            <ListItemSecondaryAction>
                                                                                <IconButton edge="end" aria-label="delete" onClick={() => this.removeUser(value)} disabled={this.state.iconDisabled1}>
                                                                                    <CloseIcon fontSize='small' />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )}
                                                                </List> : 'There is no assigned users'}
                                                        </Box>
                                                    </Box>

                                                    <Box mt={1}>
                                                        <Box fontWeight={'bold'}>Suggestions</Box>
                                                        <Box mt={1}>
                                                            {this.state.suggested_users.length !== 0 ?
                                                                <List dense>
                                                                    {this.state.suggested_users.map((value, i) =>
                                                                        <ListItem key={i}>
                                                                            <ListItemAvatar>
                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'10px'}>{value.first_name.charAt(0) + value.last_name.charAt(0)}</Box></Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={value.last_name + ", " + value.first_name}
                                                                            />
                                                                            <ListItemSecondaryAction>
                                                                                <IconButton edge="end" aria-label="delete" onClick={() => this.addUser(value)} disabled={this.state.iconDisabled}>
                                                                                    <AddIcon fontSize='small' />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )}
                                                                </List> : 'Users assigned to the tasks are displayed here'}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Popover>
                                    </div>
                                )}
                            </PopupState>
                        </Box>
                    </CardActions>
                </Card >
            </>

        )
    }
}
export default withStyles(useStyles)(withSnackbar(CustomCard))