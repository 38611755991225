import { Box, Button, Card, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Service from '../Networking/NetworkingUtils'
import { setCookie, getCookie } from '../Utils/CookieHelper';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoopCircleLoading } from 'react-loadingg';
import { Link } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';

const dark = getCookie('theme_mode')

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        height: 220,
        display: 'flex',
    },
}));

var fetchDone = {
    one: false,
    two: false
}

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            error_message: '',
            wrongdata: false,
            isLoading: false,
            allow: false,
            dialog: false,
            mailDialog: false,
            submitDisabled: false
        }
    }
    componentDidMount() {
        if (getCookie("bb_metaverse_token")) {
            window.location.href = "/"
        } else {
            this.setState({ allow: true })
        }
    }

    handleDataChange(e) {
        if (e.target.id === 'username') {
            this.setState({ username: e.target.value })
        }
        if (e.target.id === 'password') {
            this.setState({ password: e.target.value })
        }
        if (e.target.id === 'email') {
            this.setState({ email: e.target.value })
        }
    }

    getAccessImdt(token) {
        // var access = getCookie("bb_ts_access")
        Service.get(`/api/token/access/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data && res.data[0]) {
                    fetchDone.one = true
                    if (getCookie("bb_metaverse_access")) {
                        if (JSON.parse(getCookie("bb_metaverse_access")).last_updated !== res.data[0].last_updated) {
                            setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            window.location.href = '/'
                        }
                    } else {
                        setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                        window.location.href = '/'
                    }

                } else {
                    setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                    window.location.href = '/'
                }
                if (fetchDone.one && fetchDone.two) {
                    window.location.reload()
                }
            })
            .catch(error => {

                setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            });

    }

    handleSubmit(e) {
        e.preventDefault();
        var fields = ''
        if (!this.state.username) {
            fields += 'Please enter Email'
        }
        if (!this.state.password) {
            fields += (!fields) ? "Please enter Password" : ", Password"
        }
        if (fields) {
            this.props.enqueueSnackbar(fields, {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true })
            var errormessage = 'Something went wrong!';
            Service.post('/rest-auth/login/', {
                data: {
                    email: this.state.username.toLowerCase(),
                    password: this.state.password
                },
            })
                .then(res => {
                    Service.get(`/rest-auth/user/`, {
                        headers: {
                            Authorization: "Token " + res.data.key,
                        },
                    })
                        .then(res1 => {
                            this.getAccessImdt(res.data.key)
                            setCookie('bb_metaverse_token', res.data.key, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },);
                            setCookie('bb_metaverse_user', JSON.stringify(res1.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                            // window.location.href = '/'
                            this.setState({ wrongdata: false, isLoading: false })
                        })
                        .catch(error => {
                            // if (error && error.status === 400) {
                            //     this.props.enqueueSnackbar('Please check your entered credentials!.', {
                            //         variant: 'error',
                            //     });
                            // } else {
                            //     this.props.enqueueSnackbar('Something went wrong!.', {
                            //         variant: 'error',
                            //     });
                            // }
                            this.setState({ error_message: errormessage, wrongdata: true, isLoading: false })
                        });
                })
                .catch(error => {
                    try {
                        if (error.data.non_field_errors) {
                            for (let i = 0; i < error.data.non_field_errors.length; i++) {
                                const element = error.data.non_field_errors[i];
                                if (i === 0) {
                                    errormessage = element;
                                } else {
                                    errormessage = errormessage + "\n" + element;
                                }

                            }
                        } else if (error.data.email) {
                            for (let i = 0; i < error.data.email.length; i++) {
                                const element = error.data.email[i];
                                if (i === 0) {
                                    errormessage = element;
                                } else {
                                    errormessage = errormessage + "\n" + element;
                                }

                            }
                        } else if (error.data.password) {
                            for (let i = 0; i < error.data.password.length; i++) {
                                const element = error.data.password[i];
                                if (i === 0) {
                                    errormessage = element;
                                } else {
                                    errormessage = errormessage + "\n" + element;
                                }
                            }
                        }
                    } catch (error) {
                    }
                    this.setState({ error_message: errormessage, wrongdata: true, isLoading: false })
                });
        }

    }

    handlePress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    handlePassword(e) {
        if (!this.state.username) {
            this.setState({
                mailDialog: true
            })
        } else if (!this.state.username.includes("@balfourbeatty.com")) {
            this.props.enqueueSnackbar('Please provide Balfour Beatty Email ID', {
                variant: 'warning'
            })
        }
        else {
            let obj = {
                "email": this.state.username
            }

            Service.post('/api/forgot/password/forgot_password/', {
                data: obj
            }).then(res => {
                this.setState({
                    dialog: true
                })
                this.props.enqueueSnackbar('Email sent to password', {
                    variant: 'success'
                })
            }).catch(error => {
                console.log(error);
                if (error.status === 406) {
                    this.props.enqueueSnackbar('Email that you are entered didnot exist!', {
                        variant: 'warning'
                    })
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                }
            });
        }
    }

    close(e) {
        this.setState({
            dialog: false
        })
    }

    emailDialogClose(e) {
        this.setState({
            mailDialog: false
        })
    }

    submitEmail(e) {
        if (!this.state.email) {
            this.props.enqueueSnackbar('Please enter your email', {
                variant: 'warning'
            })
        } else if (!this.state.email.includes('@balfourbeatty.com')) {
            this.props.enqueueSnackbar('Please enter Balfour Beatty email', {
                variant: 'warning'
            })
        }
        else {
            this.setState({
                submitDisabled: true
            })
            let obj = {
                "email": this.state.email
            }
            Service.post('/api/forgot/password/forgot_password/', {
                data: obj
            }).then(res => {
                this.setState({
                    mailDialog: false,
                    dialog: true,
                    submitDisabled: false

                })
            }).catch(error => {
                console.log(error);
                this.setState({
                    submitDisabled: false
                })
                if (error.status === 406) {
                    this.props.enqueueSnackbar('Email that you are entered does not exist!', {
                        variant: 'warning'
                    })
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                }
            });
        }
    }

    render() {
        // const { classes } = this.props;

        return (
            <div onKeyPress={(e) => { if (this.state.allow) { this.handlePress(e) } }} tabIndex={0} style={{ outline: 'none' }}>
                {this.state.allow ?
                    <Box display='flex' flexDirection="column" justifyContent='center' marginTop='20vh'>
                        <Box display="flex" justifyContent="center">
                            <img src={"https://flipbook.bbedcindia.com/bbimages/BB_RGB_8.5mm.png"} alt="" width="400" className="pic" />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Card style={{ width: '500px', backgroundColor: dark ? '#272727' : '#eafbf4'  }}>
                                <Box display="flex" justifyContent="center" marginTop="20px">
                                    <Box sx={{ fontFamily: 'Monospace', textAlign: 'center', fontWeight: 'bold', fontSize: 25 }}>Login</Box>
                                </Box>

                                <Box marginTop="30px" display="flex" justifyContent="center">
                                    <TextField
                                        variant='outlined'
                                        autoFocus
                                        id="username"
                                        label="Email"
                                        autoComplete="text"
                                        style={{ width: 350 }}
                                        value={this.state.username}
                                        onChange={e => this.handleDataChange(e)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Box>
                                <Box marginTop="30px" display="flex" justifyContent="center">
                                    <TextField
                                        variant='outlined'
                                        id="password"
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        style={{ width: 350 }}
                                        value={this.state.password}
                                        onChange={e => this.handleDataChange(e)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </Box>
                                <Box display="flex" sx={{ width: 420, flexDirection: 'row-reverse' }} marginTop="5px">
                                    <Link component="button" variant="body2"
                                        onClick={e => this.handlePassword(e)}
                                    >
                                        Forgot Password
                                    </Link>
                                </Box>
                                {this.state.wrongdata === true ? (
                                    <p style={{ color: "red", fontSize: 14, textAlign: 'center' }}>
                                        {this.state.error_message}
                                    </p>
                                ) : null}
                                {this.state.isLoading === true ? (<Box display="flex" Box marginTop="30px" justifyContent="center" marginBottom={"30px"}><CircularProgress /></Box>) : (<Box marginTop="30px" display='flex' justifyContent="center" marginBottom={"30px"}>
                                    <Button color='primary' variant="contained" onClick={e => this.handleSubmit(e)}>Login</Button>
                                </Box>)}
                            </Card>
                        </Box>

                        <Dialog fullWidth={true} maxWidth="xs" open={this.state.mailDialog} onClose={e => this.emailDialogClose(e)}>
                            <DialogTitle>Enter your valid email</DialogTitle>
                            <DialogContent >
                                <TextField label="Email" fullWidth variant='outlined' id="email" value={this.state.email} onChange={e => this.handleDataChange(e)} ></TextField>
                                <DialogActions>
                                    <Button text="contained" disabled={this.state.submitDisabled} color="primary" onClick={e => this.submitEmail(e)}>Submit</Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>

                        <Dialog fullWidth={true} maxWidth={'xs'} open={this.state.dialog} onClose={e => this.close(e)}>
                            <DialogContent>
                                <Box sx={{ fontSize: '16px', fontWeight: 500 }}>
                                    Your UserName & Password will be share to your email shortly!
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color='primary' onClick={e => this.close(e)}>ok</Button>
                            </DialogActions>
                        </Dialog>
                    </Box > : <LoopCircleLoading />}
            </div >
        )
    }
}

export default withSnackbar(withStyles(useStyles)(Login))