import { Box, List, ListItem, ListItemButton } from '@mui/material';
import React, { useState } from 'react'
import { blobDomain, handleCacheControl } from '../Helper/BlobHelper'
import { blobSAS } from '../Helper/BlobHelper'
import { useSnackbar } from "notistack";
import { Dialog, ListItemText } from '@material-ui/core';
import WEBGLPlayer from '../ProjectList/WEBGLPlayer';


const OpenAppList = (props) => {
    const [webglDialog, setWebglDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [url, setUrl] = useState({})
    const [assetData, setAssetData] = useState({});


    const handleWebglDialog = () => {
        setAssetData({})
        setWebglDialog(false)
    }
    const setSource = (data) => {

        let asset = data.asset_source.split(",")

        const obj = {}
        for (let index = 0; index < asset.length; index++) {
            const element = asset[index];
            
            if (element.includes(".data")) {
                obj.dataUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".wasm")) {
                obj.codeUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".loader.js")) {
                obj.loaderUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".framework.js")) {
                obj.frameworkUrl = blobDomain() + element + "?" + blobSAS()
            }

            obj.cacheControl = handleCacheControl
        }


        let objkey = Object.keys(obj)

        if (objkey.length === 5) {
            setUrl(obj)
            assetData.version = data.asset_version_name
            assetData.versionCode = data.asset_version_code
            assetData.asset_id = data.id
            assetData.version_id = data.asset_version_id
            setWebglDialog(true)
        } else {
            enqueueSnackbar('Some files are missing', {
                variant: 'warning'
            })
        }
    }

    return (
        <div>
            <List dense={true}>
                {props.apps.map((data, index) => {
                    return (
                        <ListItemButton onClick={(e) => { setSource(data) }}>
                            <ListItem
                           
                            >
                                <ListItemText
                                    primary={data.name}
                                    secondary={data.description ? data.description : null}
                                />
                            </ListItem>
                        </ListItemButton>
                    )
                })}
            </List>
            <Dialog fullWidth={true} maxWidth="xl" open={webglDialog}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ m: 2 }}>
                        <WEBGLPlayer url={url} close={handleWebglDialog} assetData={assetData} />
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default OpenAppList