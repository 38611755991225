import MaterialTable from 'material-table'
import React, { useEffect } from 'react'
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper';
import { useSnackbar } from "notistack";


var token = getCookie("bb_metaverse_token");


const ProjectAssets = (props) => {


    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState([])
    const { enqueueSnackbar } = useSnackbar();

    const getData = () =>{
        Service.get(`/api/projects/asset/?project=${props.row.id}`, {
            headers: {
              Authorization: "Token " + token,
            },
          }).then(res => {
            setData(res.data)
        setLoading(false)

          }).catch(error => {
            console.log(error);
            enqueueSnackbar('Something went wrong!', {
              variant: 'error'
            })
          })
    }

    useEffect(() => {
        setLoading(true)
      getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

  return (
    <div>
        <MaterialTable
        title="Project Asset"
        isLoading={loading}
        data={data}
        columns={[
            { title: 'Name', field: 'asset_name'},
            { title: 'Asset Source', field: 'asset_source'}
            // { title: 'File Name', field: 'asset_parameter', render: rowData => rowData.open_file}}
        ]}
        />
    </div>
  )
}

export default ProjectAssets