import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React, { Component } from 'react';
import { getCookie, deleteCookie, setCookie } from './Utils/CookieHelper';
import Service from './Networking/NetworkingUtils'
import { WindMillLoading } from 'react-loadingg';
import Login from './Login/Login';
import Home from './Home/Home';
// import ChangePassword from './Home/ChangePassword';
import DirectChangePassword from './DirectChangePassword';
import DirectForgotPassword from './DirectForgotPassword';
import { getPrimaryColor, increseCountTime } from './Helper/CommonThings';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './Login/authProvider';
import { basicReduxStore } from './Login/reduxStore';
// import NoAccess from './NoAccess';

var CryptoJS = require("crypto-js");

const list_of_excluded_domains = ["cloudapp.azure.com", "localhost1"]

var key = CryptoJS.enc.Utf8.parse('1234567890123456'); // TODO change to something with more entropy 

export function jwtEncryptKey() {
  return key
}

var fetchDone = {
  one: false,
  two: false
}

// const host_name = window.location.hostname

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      go: (check_excluded_domains())
    }
  }

  componentDidMount() {
    if (getCookie("bb_metaverse_token")) {
      this.checkToken();
      this.getAccess();
    } else if (check_excluded_domains()) {
      if (!window.location.href.includes('/login')) {
        window.location.href = '/login'
        deleteCookie("bb_metaverse_token");
        deleteCookie("bb_metaverse_user");
        deleteCookie("bb_metaverse_access");
      }
      this.setGo()
    } else {
      deleteCookie("bb_metaverse_token");
      deleteCookie("bb_metaverse_user");
      deleteCookie("bb_metaverse_access");
    }
  }

  setGo() {
    setTimeout(() => { this.setState({ go: true }) }, 50);
  }

  getAccess() {
    var access = getCookie("bb_metaverse_access")
    this.getAccessImdt(getCookie("bb_metaverse_token"))
    setInterval(function () {
      if (increseCountTime() < 13) {
        Service.get(`/api/token/access/`, {
          headers: {
            Authorization: "Token " + getCookie("bb_metaverse_token"),
          },
        })
          .then(res => {
            if (res.data && res.data[0] && JSON.parse(access)) {
              if (JSON.parse(access).last_updated !== res.data[0].last_updated) {
                setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                window.location.reload();
              }
              if (!getCookie("bb_metaverse_access")) {
                setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
                window.location.reload();
              }
            } else if (res.data && res.data[0] && !getCookie("bb_metaverse_access")) {
              setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
              window.location.reload();
            } else {
              setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            }
          })
          .catch(error => {
            setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
          });
      }
    }, 300000);
  }

  getAccessImdt(token, with_refresh = false) {
    Service.get(`/api/token/access/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        if (res.data && res.data[0]) {
          if (getCookie("bb_metaverse_access")) {
            if (JSON.parse(getCookie("bb_metaverse_access")).last_updated !== res.data[0].last_updated) {
              setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            }
            fetchDone.one = true
          } else {
            setCookie('bb_metaverse_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
            fetchDone.one = true
          }
        } else {
          fetchDone.one = true
          setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
        }

        if (with_refresh) {
          window.location.reload()
        }

        console.log(fetchDone.one, window.location.pathname, !with_refresh)

        if (fetchDone.one && window.location.pathname === '/' && !with_refresh) {
          window.location.href = "/home"
        }

      })
      .catch(error => {
        setCookie('bb_metaverse_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
      });
  }

  checkToken() {
    Service.get(`/api/token/valid/check/`, {
      headers: {
        Authorization: "Token " + getCookie("bb_metaverse_token"),
      },
    })
      .then(res => {
        if (fetchDone.one && window.location.pathname === '/') {
          window.location.href = '/home'
        }
        this.setGo()
      })
      .catch(error => {
        if (error && error.status === 401) {
          deleteCookie("bb_metaverse_token");
          deleteCookie("bb_metaverse_user");
          window.location.reload()
        }
      });
  }

  getTokenFromDj(data) {
    Service.patch(`/api/generate/token/token_update/`, {
      data: {
        id_token: encryptJWT("D1R1P" + data.jwtIdToken + "D1R1P", key),
      }
    })
      .then(res => {
        // console.log(res.data)
        if (res.data && res.data.token && res.data.user) {

          setCookie('bb_metaverse_token', res.data.token, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);
          setCookie('bb_metaverse_user', JSON.stringify(res.data.user), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);

          this.getAccessImdt(res.data.token, true)

        } else {
          alert("We haven't added you to BBVerse.")
        }

      })
      .catch(error => {
        if (error.status === 406) {
          alert("We haven't added you to BBVerse.")
        }
        console.log(error)
      });
  }

  render() {
    return (
      <div>
        {
          (!this.state.go) ? (
            <AzureAD provider={authProvider} reduxStore={basicReduxStore} forceLogin={!getCookie("bb_metaverse_token")} >
              {
                ({ login, logout, authenticationState, error, accountInfo }) => {

                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:

                      if (!getCookie("bb_metaverse_token")) {
                        this.getTokenFromDj(accountInfo)
                      } else {
                        this.setGo()
                      }
                      // this.getTokenFromDj(accountInfo)

                      return (
                        <>
                          {(this.state.go) ? (
                            <AppRoute />
                          ) : (
                            null
                          )}

                        </>

                      );
                    case AuthenticationState.Unauthenticated:
                      console.log(error)

                      deleteCookie("bb_metaverse_token");
                      deleteCookie("bb_metaverse_user");


                      // if (window.location.pathname !== '/') {
                      //   window.location.href = '/'
                      // }

                      return (<p>Authenticating...</p>);
                    case AuthenticationState.InProgress:
                      return (<p>Authenticating...</p>);
                    default:
                      setTimeout(() => {
                        if (authenticationState !== AuthenticationState.Authenticated) {
                          window.location.reload()
                        }
                      }, 3000);
                      return (<p>Authenticating...</p>);
                  }
                }
              }

            </AzureAD>
          ) : (
            <AppRoute />
          )
        }
        {(this.state.go) ? (
          null
        ) : (
          <WindMillLoading color={getPrimaryColor()} />
        )}

      </div>
    )
  }
}
export default App


function AppRoute() {
  return (
    <BrowserRouter >
      <Switch>
        <Route exact path="/" render={(e) => <WindMillLoading color={getPrimaryColor()} />} />
        <Route path='/home' render={() => <Home />} />
        <Route path='/login' render={() => <Login />} />
        <Route path='/change_password' render={() => <DirectChangePassword />} />
        <Route path='/forgot_password' render={() => <DirectForgotPassword />} />
      </Switch>
    </BrowserRouter>
  );
}

export function encryptJWT(msgString, key) {
  // msgString is expected to be Utf8 encoded
  var iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(msgString, key, {
    iv: iv
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

function check_excluded_domains() {
  for (var i = 0; i < list_of_excluded_domains.length; i++) {
    if (window.location.hostname.includes(list_of_excluded_domains[i])) {
      return true
    }
  }
}