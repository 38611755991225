import { Box,  List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useState } from 'react'
import WEBGLPlayer from './WEBGLPlayer';
import { useSnackbar } from "notistack";
import { useEffect } from 'react';
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { blobDomain, blobSAS, handleCacheControl } from '../Helper/BlobHelper';
import { Dialog } from '@material-ui/core';

var token = getCookie("bb_metaverse_token");

const VersionLists = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([])
    const [url, setUrl] = useState({})
    const [webglDialog, setWebglDialog] = useState(false);
    const [assetData] = useState({});

    const handleWebglDialog = () => {
        setWebglDialog(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getData() {
        Service.get(`/api/asset/version/get_version_by_project/?project_asset=${props.data.id} `, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            setData(res.data)

        }).catch(error => {
            console.log(error);
            enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            })
        })
    }
    const setSource = (data) => {

        let asset = data.asset_source.split(",")

        const obj = {}
        for (let index = 0; index < asset.length; index++) {
            const element = asset[index];

            
            if (element.includes(".data")) {
                obj.dataUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".wasm")) {
                obj.codeUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".loader.js")) {
                obj.loaderUrl = blobDomain() + element + "?" + blobSAS()
            } else if (element.includes(".framework.js")) {
                obj.frameworkUrl = blobDomain() + element + "?" + blobSAS()
            }

            obj.cacheControl = handleCacheControl
        }

        let objkey = Object.keys(obj)


        if (objkey.length === 5) {
            setUrl(obj)
            assetData.version = data.version
            assetData.versionCode = data.version_code
            assetData.asset_id = data.project_asset
            assetData.version_id = data.id
            setWebglDialog(true)
        } else {

            enqueueSnackbar('Some files are missing', {
                variant: 'warning'
            })
        }
    }

    return (
        <div>
            <List dense={true}>
                {data.map((data, index) => {
                    return (
                        <ListItemButton onClick={(e) => { setSource(data) }}>
                            <ListItem >
                                <ListItemText
                                    primary={data.version}
                                    secondary={data.change_log ? data.change_log : null}
                                />
                            </ListItem>
                        </ListItemButton>
                    )
                })}
            </List>
            <Dialog fullWidth={true} maxWidth="xl" open={webglDialog}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ m: 2 }}>
                        <WEBGLPlayer url={url} close={handleWebglDialog} assetData={assetData} />
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default VersionLists