import React, { Component } from 'react'
import { Box, Button, Card, CardActions, TextField, } from '@material-ui/core';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withSnackbar } from 'notistack';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCardWidth, getPriority } from './KanbanHelper';
import Service from '../Networking/NetworkingUtils';
import { getCookie } from '../Utils/CookieHelper';
import { getMode } from '../Helper/CommonThings';

const token = getCookie("bb_metaverse_token");
const user = JSON.parse(getCookie("bb_metaverse_user"));
class CustomAddCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            task: '',
            planned_start: this.props.subTaskData ? this.props.subTaskData.planned_start : this.props.task_details?.start ? this.props.task_details?.start : null,
            planned_end: this.props.subTaskData ? this.props.subTaskData.planned_end : this.props.task_details?.end ? this.props.task_details?.end : null,
            taskId: this.props.subTaskData ? this.props.subTaskData.ohlwbs_tasks : this.props.task_id,
            taskDetails: this.props.task_details,
            priority: null
        }
    }

    handleTextChange(e) {
        if (e.target.id === 'taskname') {
            this.setState({
                task: e.target.value,
            })
        }
    }

    addItem() {
        var fields = ''
        // if (!this.state.taskId) {
        //     fields += 'Select Task'
        // }
        if (!this.state.task) {
            fields += (!fields) ? 'Sub Task name' : ', Sub Task name'
        }
        if (!this.state.planned_start) {
            fields += (!fields) ? 'Planned Start' : ', Planned Start'
        }
        if (!this.state.planned_end) {
            fields += (!fields) ? 'Planned End' : ', Planned End'
        }

        if (fields) {
            // this.props.alertMsg(fields + ' is Empty!', 'warning');
            this.props.enqueueSnackbar(fields + ' is Empty!', { variant: 'warning' });

        } else {
            if ((moment(this.state.planned_end).format('YYYY-MM-DD')) >= (moment(this.state.planned_start).format('YYYY-MM-DD'))) {
                var data = {
                    name: this.state.task,
                    planned_start: moment(this.state.planned_start).format('YYYY-MM-DD'),
                    planned_end: moment(this.state.planned_end).format('YYYY-MM-DD'),
                    priority: this.state.priority,
                    workorder_status: this.props.props.laneId,
                    comment_type_list: "user_input_bbverse",
                    version: this.props.version.id,
                    updated_by: user.id,
                    created_by: user.id
                }
                if (this.props.subTaskData) {
                    data.subtask = this.props.subTaskData.id
                }
                Service.post(`/api/work/order/add_workorder/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: data
                })
                    .then(res => {
                        // this.props.alertMsg('Data added successfully!', 'success');
                        this.props.enqueueSnackbar('Data added successfully!', { variant: 'success' });
                        this.props.addCard(res.data, this.props.props.laneId)
                        this.props.props.onCancel()
                    })
                    .catch(error => {
                        console.log(error)
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                        // this.props.alertMsg('Something went wrong!', 'error');
                    });
            } else {
                // this.props.alertMsg('End dates are not lesser than Start dates!', 'warning');
                this.props.enqueueSnackbar('End dates are not lesser than Start dates!', { variant: 'warning' });

            }
        }
    }

    onTagsChange = (event, values) => {
        this.setState({
            taskId: values.id,
            taskDetails: values,
            planned_end: values.end,
            planned_start: values.start
        })
    }

    componentDidMount(){
        console.log(this.props)
    }


    render() {
        const handleStartChange = (date) => {
            this.setState({
                planned_start: date,
            })
        }
        const handleEndChange = (date) => {
            this.setState({
                planned_end: date,
            })
        }
        const handlePriorityChange = (event) => {
            this.setState({ priority: event.target.value });
        };

        return (
            <div>
                <Card style={{ margin: '5px', width: getCardWidth(this.props.data.lanes.length) + "px" }}>
                    <Box display={'flex'} flexDirection='column' width={'100%'} p={1}>
                        <Box width={'100%'}>
                            {(!this.props.task_id && this.props.titleData?.task_list) ?
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.titleData.task_list}
                                    getOptionLabel={(option) => option.detail_name}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                    onChange={this.onTagsChange}
                                    renderInput={(params) => <TextField {...params} label="Select Task" variant="outlined" />}
                                />
                                : null}
                        </Box>
                        <Box width={'100%'}>
                            <TextField id="taskname" label="Sub Task Name" variant="outlined" style={{ width: '100%' }} onChange={e => this.handleTextChange(e)} />
                        </Box>
                        <Box display={'flex'} flexDirection='row' width={'100%'} justifyContent='sapce-around'>
                            <Box marginRight={'5px'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant='outlined'
                                        margin="normal"
                                        format="dd/MM/yyyy"
                                        value={this.state.planned_start}
                                        onChange={handleStartChange}
                                        clearable
                                        fullWidth
                                        KeyboardButtonProps={{
                                            'aria-label': 'Planned start',
                                        }}
                                        label='Planned Start'
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant='outlined'
                                        margin="normal"
                                        format="dd/MM/yyyy"
                                        value={this.state.planned_end}
                                        onChange={handleEndChange}
                                        clearable
                                        fullWidth
                                        KeyboardButtonProps={{
                                            'aria-label': 'Planned end',
                                        }}
                                        label='Planned End'
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Box>
                        <Box width={'100%'} mt={1}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-outlined-label">Priority</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="priority"
                                    value={this.state.priority}
                                    onChange={handlePriorityChange}
                                    label="Priority"
                                >
                                    <MenuItem value={null}>None</MenuItem>
                                    {getPriority().map((value, i) =>
                                        <MenuItem key={i} value={value.key}>{value.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <CardActions >
                        <Box display='flex' flexDirection={'row'} alignItems='center'>
                            <Button 
                            color={getMode() ? 'secondary' : 'primary'} 
                            onClick={() => this.addItem()}>Add</Button>
                            <Button 
                            color={getMode() ? 'secondary' : 'primary'} 
                            onClick={() => this.props.props.onCancel()}>Cancel</Button>
                        </Box>
                    </CardActions>
                </Card></div>
        )
    }
}
export default withSnackbar(CustomAddCard)