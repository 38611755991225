import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Accordions from './Accordions';
import { Box } from '@material-ui/core';
import Service from '../Networking/NetworkingUtils'
import { getCookie } from '../Utils/CookieHelper';
import CircleToBlockLoading from 'react-loadingg/lib/CircleToBlockLoading';
import errorMessage from '../Networking/ErrorMessage';
// import TimeSheetDialog from '../../TimeSheet/TimeSheetDialog';

const token = getCookie("bb_metaverse_token");


class UserPermission extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            comData: {},
            resData: {},
        }
        this.rewriteData = this.rewriteData.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/api/access/?uid=` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                });
                this.compileData(res.data);
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    compileData(response) {
        var loca = {
            l0a: {
                l0a_a: false,
                l0a_b: false,
            },
        };
        var data = {
            l0a: {
                left: [],
                right: [],
            },
        };

        if (response.length !== 0) {
            loca = response[0].access;
        }
        if (!loca.l0a) {
            loca.l0a = {}
        }
      

        if (loca.l0a && loca.l0a.l0a_a) {
            data.l0a.right.push({
                id: 'l0a_a',
                name: 'View Project Tab'
            })
        } else if (loca.l0a) {
            data.l0a.left.push({
                id: 'l0a_a',
                name: 'View Project Tab'
            })
        }

        if (loca.l0a && loca.l0a.l0a_b) {
            data.l0a.right.push({
                id: 'l0a_b',
                name: 'Admin for Projects'
            })
        } else if (loca.l0a) {
            data.l0a.left.push({
                id: 'l0a_b',
                name: 'Admin for Projects'
            })
        }

        this.setState({ comData: data, isLoading: false, resData: loca })

    }

    rewriteData(data, loca, response, userData) {

        if (data.l0a) {
            for (let i = 0; i < data.l0a.left.length; i++) {
                const element = data.l0a.left[i];
                loca.l0a[element.id] = false;
            }
            for (let i = 0; i < data.l0a.right.length; i++) {
                const element = data.l0a.right[i];
                loca.l0a[element.id] = true;
            }
        }

        var dataTo = {
            uid: userData.id,
            access: loca,
            last_updated: new Date(),
        }

        if (response.length === 0) {
            this.setState({ isLoading: true })
            Service.post(`/api/access/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    errorMessage(this.props, error)
                });
        } else {
            Service.put('/api/access/' + response[0].id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }

    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#008755' />
                ) : (
                    <div>
                        <Box p={3} bgcolor="#d5f6e9">
                            <Accordions value={this.state.comData} resData={this.state.resData} user={this.props.value} data={this.state.data} handleToUpdate={this.rewriteData} />
                        </Box>
                    </div>
                )}
            </div>
        )
    }
}


export default withSnackbar(UserPermission);